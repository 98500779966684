import { render, staticRenderFns } from "./ThreadsOverlay.vue?vue&type=template&id=1adb1cfa&scoped=true&"
import script from "./ThreadsOverlay.vue?vue&type=script&lang=js&"
export * from "./ThreadsOverlay.vue?vue&type=script&lang=js&"
import style0 from "./ThreadsOverlay.vue?vue&type=style&index=0&id=1adb1cfa&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1adb1cfa",
  null
  
)

export default component.exports