<template>
  <!-- TODO: Fix; topic page (article links) are a bit broken (v3) and nth topic doesn't reload data weridly? -->
  <router-link :to="`/topic/${topic.topic_code}`" class="meta-image-wrapper" :class="{ 'mini' : mini }" :title="topicDescription" v-if="Object.keys(meta).length > 0 && meta.name">
    <div class="image" v-if="meta && meta.image && meta.image !== 'https://onesub.io'">
      <img :src="meta.image" :alt="meta.name"/>
    </div>

    <div class="content">
      <h4>{{ meta.name }}</h4>
      <h5 v-if="!mini && meta">{{ meta.byline }}</h5>
    </div>

    <!-- <div class="topic-hover">
      <span>This story has a more <span class="sentiment" :class="getSentimentClass">{{ getSentiment }}</span> sentiment towards <span class="callout">{{ topic_name }}</span></span>
    </div> -->
  </router-link>
</template>

<script>
  // -- 
  export default {
    props: {
      'mini': Boolean, 
      'topic': Object,
      'type': String,
    },

    computed: {
      
      topicDescription() {
        if (this.meta) {
          return this.meta.description
        }
        return '';
      },

      data() {
        return this.$store.getters.getLibraryItem('topics_v3', this.topic.topic_code);
      },

      meta() {
        return this.data?.meta || {};
      },

      getSentiment() {
        return 'neutral'
      },

      getSentimentClass() {
        // switch (this.sentiment) {
        //   case 'positive': return 'positive'
        //   case 'negative': return 'negative'
        //   case 'neutral': return 'neutral'
        // }
        return `sentiment-${this.getSentiment}`
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/__2020_v3.scss';

  .meta-image-wrapper {
    display: flex;
    align-items: center;
    position: relative;

    .image {
      width: 38px;
      height: 38px;
      margin-right: 0.6em;

      .mobile & {
        width: 32px;
        height: 32px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
        border: 1px solid $neutral-500;
      }
    }

    .content {
      flex: 1;
      * {
        white-space: nowrap;
        max-width: 14em;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      h4, h5 {
        margin: 0;
      }

      h4 {
        margin-bottom: 0;
        @include typo-size-small-heading;

        @media (max-width: 585px) {
          font-size: 12px;
        }
      }

      h5 {
        @include typo-size-body-small;
        line-height: 1.5;
        margin-top: 0.3em;

        @media (max-width: 585px) {
          font-size: 9px;
        }
      }
    }

    &.mini {
      .image {
        width: 20px;
        height: 20px;
        margin-right: 0.4em;
      }

      .content {
        color: rgba($yellow-tone-7, 0.4);

        h4 {
          @include typo-size-small;
          font-size: 0.65em;
        }
      }
    }

    .topic-hover {
      display: none;
      position: absolute;
      top: -175%;
      left: -25%;
      width: 250px;
      background: $white-primary;
      padding: 1em;
      border-radius: $el-border-radius;
      z-index: 1;
      @include typo-size-body-small;
      @include deep-shadow;

      .callout {
        @include typo-size-small;
      }

      .sentiment {
        z-index: 0;
      }

      .sentiment-positive {
        box-shadow: 0px -6px $green-tone-2 inset;
      }

      .sentiment-negative {
        box-shadow: 0px -6px $red-tone-2 inset;
      }

      .sentiment-neutral {
        box-shadow: 0px -6px $blue-tone-2 inset;
      }
    }

    &:hover {
      .topic-hover {
        display: inline;
      }
    }
  }
</style>