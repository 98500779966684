<template>
  <div class="footer-navigation-wrapper">
    <div class="inner-wrapper">
      <Share :shareUrl="`story/${slug}/${node}`" :shareMessage="headline" :story="{slug: slug, node: node}"/>
      <div class="progress">
        <ProgressTag v-if="getPrimarySlug" :slug="getPrimarySlug" :active="true" class="progress-tag"/>
      </div>
    </div>

    <!-- <div class="next-story" v-if="showNextStory">
      <Story class="inner-story" :story="getNextStory"/>
    </div>

    <div class="tab-bar">
      <div class="actions">
        <div class="back" @click="didSelectBack()" v-if="!levelIsRoot">
          <global-svg class="icon" type="back-arrow" color="#FFF" height="16px"></global-svg>
        </div>

        <div class="variant-stack">
          <div class="show-variant" :class="{'active' : getNextMode == 'section'}" @click="didSelectVariant('section')" v-if="isSignedIn && showNextStory">
            Next Story
          </div>

          <div class="show-variant" :class="{'active' : getNextMode == 'importance'}" @click="didSelectVariant('importance')" v-if="isSignedIn && showNextStory">
            Next Most Important
            <div class="pro-tag" v-if="!isPro">Pro</div>
          </div>

          <div class="show-variant" :class="{'active' : getNextMode == 'interest'}" @click="didSelectVariant('interest')" v-if="isSignedIn && showNextStory">
            Next Most Interesting
            <div class="pro-tag">Coming Soon</div>
          </div>

          <div class="show-variant brand" @click="showOneSubInfo()" v-if="!isSignedIn">
            Get the most out of OneSub
            <div class="brand-tag">Learn More</div>
          </div>
        </div>

        <div class="stats" :class="getViewBindingClass" v-if="isSignedIn && a11yDialOn">
          <div class="inner-action outer-ring" v-if="!showNextStory">
            <span class="detail">Sources: </span>
            <span class="stat">{{ getPublishersStat || 0 }}</span>
          </div>

          <div class="inner-action inner-ring" v-if="!showNextStory">
            <span class="detail">Time: </span>
            <span class="stat">{{ getReadingTimeStat || 0  }}m</span>
          </div>

          <div class="inner-action mid-ring" v-if="!showNextStory">
            <span class="detail">Countries & Topics: </span>
            <span class="stat">{{ getTopicsStat || 0  }}</span>
          </div>
        </div>
      </div>

      <div class="navigation">
        <div class="inner-navigation" @click="EventBus.$emit('menu:toggle');">
          <global-svg type="hamburger" width="13px" height="13px" color="black"/>
        </div>
      </div>

      <div class="dial" :class="{'acc-off' : a11yDialOff}" @click="showReportStats">
        <dial size="tab" class="dial-small" v-if="!a11yDialOff"/>
      </div>
    </div> -->

  </div>
</template>

<script>
  import utils from '@assets/js/utils.js';
  import layout from '@assets/js/layout.js';
  import story from '@assets/js/news/story-utils.js';
  import ProgressTag from 'app/components/_v5/grid-elements/meta/ProgressTag.vue' 
  import Share from "@/components/_v5/base/Share"

  export default {
    mixins: [story, utils, layout],

    data() {
      return {}
    },

    components: {
      ProgressTag,
      Share
    },

    computed: {
      isSignedIn() {
        return this.$store.getters.isLoggedIn; 
      },

      getPrimarySlug() {
        return this.$route.params.slug || false;
      },

      showNextStory() {
        return this.$dataEngine.nextStory.slug;
      },
      
      showImportantStory() {
        return false;
      },
      
      getNextMode() {
        return this.$userEngine.getPref('layout.next') || 'section';
      },

      getNextStory() {
        switch (this.getNextMode) {
          case 'importance' : 
            return this.$dataEngine.nextByScore;
          default:
            return this.$dataEngine.nextBySection;
        }
      },

      getNextImportantStory() {
        return this.$dataEngine.nextStory;
      },

      dialData() {
        return this.$store.getters.getLibraryItem('dial');
      },

      // Stats
      getPublishersStat() {
        if (this.dialData.rings) {
          return this.dialData.rings.sources.cx;
        }
        return 0;
      },

      getTopicsStat() {
        if (this.dialData.rings) {
          return this.dialData.rings.topics.cx;
        }
        return 0;
      },

      getReadingTimeStat() {
        if (this.dialData.rings) {
          const timeInSeconds = this.dialData.rings.time.cx
          return Math.ceil(timeInSeconds / 60);
        }
        return 0;
      },

      // Percentage Complete
      getPublishersPosition() {
        return Math.ceil(this.dialData.rings.sources.px);
      },

      getTopicsPosition() {
        return Math.ceil(this.dialData.rings.topics.px);
      },

      getReadingTimePosition() {
        return Math.ceil(this.dialData.rings.time.px);
      },

      getOverallProgress() {
        const totals = this.getPublishersPosition + this.getTopicsPosition + this.getReadingTimePosition;
        return Math.ceil(totals / 3);
      }
    },

    methods: {
      didSelectVariant(sVariant) {
        if (sVariant == 'interest') {
          document.location.hash = '#coming_soon_most_interesting';
          return; // not yet installed.. 
        }
        if (!this.isPro && (sVariant != 'section')) {
        // if ((sVariant != 'section')) {
          document.location.hash = '#go_pro_nextup';
          return; // not yet installed.. 
        }
        this.$userEngine.setPref('layout.next', sVariant);
      },

      didSelectBack() {
        // this.$router.push(`/v3`);
        this.$router.push(`/`);
      },

      showReportStats() {
        document.location.hash = this.isSignedIn ? '#report_today' : '#report_mock';
      },

      showOneSubInfo() {
        document.location.hash = '#v3_intro';
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/__2020_v3.scss';
  @import 'src/assets/scss/v5.scss';

  .footer-navigation-wrapper {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: $iZIndexFooter;
    padding-bottom: env(safe-area-inset-bottom);
    background: $neutral-600;

    .darkmode & {
      background: $neutral-200;
    }

    .inner-wrapper {
      display: flex;
      justify-content: space-between;
      padding: 0.75rem 1rem;
      padding-bottom: calc(env(safe-area-inset-bottom) + 0.75rem);

      .progress {
        cursor: pointer;
        .progress {
          margin-bottom: 0;
        }
      }
    }
  }

  // .footer-navigation-wrapper {
    // position: fixed;
    // bottom: 0;
    // width: 100%;
    // z-index: $iZIndexFooter;
    // padding-bottom: env(safe-area-inset-bottom);
  //   background: $base-primary;

  //   .next-story {
  //     // background: $white-primary;
  //     // border-top: 1px solid $neutral-450;
  //     @include palette-default;
  //     @include palette-border-top;
  //     padding-right: 5em;
  //     @include deep-shadow;

  //     // a11y - no dial? fill width.
  //     .a11yDialOff & {
  //       padding-right: initial;
  //     }
  //   }

  //   .tab-bar {
  //     position: relative;
  //     background: $base-primary;

  //     .actions {
  //       min-height: 40px;
  //       display: flex;

  //       .back {
  //         padding: 1em;
  //       }

  //       .variant-stack {
  //         align-items: center;
  //         display: -webkit-box;
  //         overflow-x: scroll;
  //         overflow-y: hidden;
  //         -ms-overflow-style: none;  /* IE and Edge */
  //         scrollbar-width: none;  /* Firefox */

  //         &::-webkit-scrollbar {
  //           display: none;
  //         }
  //       }

  //       .show-variant {
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //         @include typo-size-small;
  //         color: $white-primary;
  //         padding: 0 1.2em;
  //         margin-left: 3px;
  //         white-space: nowrap;
  //         height: 100%;
  //         @include ease_animation;

  //         &.active {
  //           background: rgba($white-primary, 0.1)
  //         }

  //         &.brand {
  //           background: rgba($yellow-tone-3, 0.1);
  //           color: $yellow-tone-3;
  //         }

  //         &:last-child:not(:only-child) {
  //           padding-right: 100px;
  //         }

  //         .pro-tag,
  //         .brand-tag {
  //           @include typo-size-small;
  //           border-radius: $el-border-radius;
  //           margin-left: 0.5em;
  //         }

  //         .pro-tag {
  //           background: $green-tone-3;
  //         }

  //         .brand-tag {
  //           background: $yellow-tone-3;
  //           color: $yellow-tone-7;
  //           padding: 0.6em;
  //           margin-left: 1em;
  //         }
  //       }

  //       .stats {
  //         display: flex;
  //         align-self: center;
  //         margin-left: 5px;

  //         .inner-action {
  //           @include typo-size-small;
  //           padding: 0.8em;
  //           border-radius: $el-border-radius;

  //           &:not(:last-child) {
  //             margin-right: 5px;
  //           }

  //           &.outer-ring {
  //             color: $yellow-tone-3;
  //             background: rgba($yellow-tone-3, 0.1);
  //           }

  //           &.mid-ring {
  //             color: $orange-tone-3;
  //             background: rgba($orange-tone-3, 0.1);
  //           }

  //           &.inner-ring {
  //             color: $red-tone-3;
  //             background: rgba($red-tone-3, 0.1);
  //           }
  //         }

  //         &.view-bind-page,
  //         &.view-bind-detail {
  //           display: none;
  //         }
  //       }
  //     }

  //     .desktop-actions {
  //       display: flex;
  //     }

  //     .navigation {
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       position: absolute;
  //       right: 6px;
  //       bottom: 10px;
  //       background: rgba($yellow-tone-3, 0.3);
  //       width: 34px;
  //       height: 34px;
  //       border-radius: 50%;
  //       z-index: 10;

  //       .inner-navigation {
  //         display: flex;
  //         align-items: center;
  //         justify-content: center;
  //         background: $yellow-tone-3;
  //         width: 26px;
  //         height: 26px;
  //         border-radius: 50%;
  //       }
  //     }

  //     .dial {
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       position: absolute;
  //       bottom: 0;
  //       right: -10px;
  //       padding: 15px 11px 10px 11px;
  //       background: $base-primary;
  //       border-radius: 50%;
  //       &.acc-off {
  //         min-width: 60px;
  //         min-height: 60px;
  //         bottom: -5px;
  //         right: -6px;
  //       }
  //     }
  //   }
  // }
</style>