import { render, staticRenderFns } from "./Subtitles.vue?vue&type=template&id=0490e04b&scoped=true&"
import script from "./Subtitles.vue?vue&type=script&lang=js&"
export * from "./Subtitles.vue?vue&type=script&lang=js&"
import style0 from "./Subtitles.vue?vue&type=style&index=0&id=0490e04b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0490e04b",
  null
  
)

export default component.exports