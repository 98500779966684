<template>
  <div></div>
</template>

<script>
  export default {
    name: 'HandleReadArticle',
    data() {
      return {
        timeOutbound: new Date(),
        payloadOutbound: {}
      }
    },
    mounted() {
      this.EventBus.$on("didViewArticle", (payload) => {
        this.trackArticleOutbound(payload);
      });
      
      this.EventBus.$on("didSelectArticle", (payload) => {
        this.handleArticleClick(payload);
      });

      this.EventBus.$on("didSelectPreviewArticle", (payload) => {
        this.trackArticleOutbound(payload);
      });
  
      this.EventBus.$on("didClosePreview", (payload) => {
        this.trackArticleInbound(payload);
      });      
    },
    computed: {
      isAlpha() {
        return this.$store.getters.isAlpha;
      },
      isBeta() {
        return this.$store.getters.isBeta;
      },
      hasApp() {
        return this.$store.getters.hasApp;
      },
      isSignedIn() {
        return this.$store.getters.isLoggedIn;
      },
    },
    methods: {

      // Determine if article click handled within app or browser
      handleArticleClick(payload) {
        this.trackArticleOutbound(payload);
        
        // Pull normal users OUT of this flow..  (Don't know what this is!?)
        // if (this.isBeta && (payload.mode === 'preview')){
        //   return;
        // }

        // Currnent App/Standard triage..
        if (this.hasApp) {
          this.handleInApp(payload);
        } else {
          this.handleInBrowser(payload);
        }
      },

      // Handle article click in APP
      handleInApp(payload) {

        this.launchInApp(payload,'onesub')

        // App v1.4 AppStore Review Fun
        // This is kept for posterity (for handling dialogs & flags)

        //    // Prior to 1.4, just launch internally.. (the 'browser' param is ignored anyway)
        //    if (this.$store.getters.hasAppVersion < 1.4){
        //      this.launchInApp(payload,'onesub')
        //      return;
        //    }
        //    // After 1.4, if the user's already chosen our webview.. 
        //    if (this.$store.getters.getAccountFlag('webview')){
        //      this.launchInApp(payload,'onesub')
        //      return;
        //    }
        //    // After 1.4, query the user's preference (we will store this..)
          //  this.EventBus.$emit('showInboundMessage', {
          //    code: 'BRX001',
          //    callback: (code) => {
          //      if (code == 'cancel'){
          //        return;
          //      }
          //      if (code == 'onesub'){
          //        // Update the user's settings-flags
          //        API.patch(`/account/flags`, {on: 'ios-webview'}).then(() => {
          //          this.$store.commit('updateAccount', this.account);
          //        });
          //      }
          //      this.launchInApp(payload,code)
          //    }
          //  });
      },
      
      launchInApp(payload, browser) {
        // console.log('This payload read. changed2', payload);

        if (payload.article.publisher.rules) {
          this.EventBus.$emit("appCallback", {
            type: 'storyCallback',
            data: {
              browser: browser,
              articleURL: payload.article.url,
              articleSlug: payload.article.slug,
              storySlug: payload.story.slug,
              articlePublisher: payload.article.publisher.name,
              cookies: payload.article.publisher.rules.cookie_consent,
              xpath: payload.article.publisher.rules.article_bottom
            }
          });
        } else {
          this.EventBus.$emit("appCallback", {
            type: 'storyCallback',
            data: {
              browser: browser,
              articleURL: payload.article.url,
              articleSlug: payload.article.slug,
              storySlug: payload.story.slug,
              articlePublisher: payload.article.publisher.name,
            }
          });
        }
      },

      // Handle article click in BROWSER
      handleInBrowser(payload) {
        this.payloadOutbound = payload;
        window.addEventListener('focus', this.trackArticleInbound);
        var win = window.open(payload.article.url, '_blank');
        if (win) {
          win.focus();
        } else {
          // Show popup explaining that the browser blocked us opening a new window!
          alert("Your browser didn't let us open the new article");
        }
      },


      // Handle outbound tracking
      trackArticleOutbound(payload) {
        this.timeOutbound = new Date();
        
        this.EventBus.$emit("deviceLog", 'Tracking: PUBLISHER');
        // console.log("Tracking: PUBLISHER", payload);
        
        this.EventBus.$emit('trackArticleAction',{
          article: payload.article,
          story: payload.story,
          state: 'clicked',
        });
        
      },

      // Handle inbound tracking
      trackArticleInbound(payload) {
        window.removeEventListener('focus', this.trackArticleInbound);
        
        let inbound = new Date();
        let seconds = (inbound.getTime() - this.timeOutbound.getTime()) / 1000;
        let time = parseInt(seconds);

        payload = {...{
          article: this.payloadOutbound.article,
          story: this.payloadOutbound.story,
          time: time
        },...payload};

        // console.log("Tracking: Consolidated payload", payload);

        // generally overwrite with the given payload
        this.EventBus.$emit('didReturnFromArticle', payload);
      },	
    }

  }
</script>