<template>
  <div class="xtw_outer" style="min-height: 140px;">
    <div class="xtw_wrap" ref="charlie">
      <Charlie v-if="icon" height="34px"/>
    </div>
    
    <div class="xtw_inner" ref="subtitles">
      <fragment v-for="(line, i) in lines" :key="i">
        <transition name="fade">
          <div :data-line="i" class="xtw_line" v-if="i >= (lines.length - getMaxRows)">
            <transition-group name="fade">
              <div class="xtw_word inline-block py-0.5 pl-1 jsx-show" v-for="(word, ii) in line.words" :key="`${i}__${ii}`">
                {{word}}
              </div>
            </transition-group>
          </div>
        </transition>
      </fragment>
    </div>
  </div>
</template>
<script>
import { Fragment } from 'vue-fragment';
export default {
  props: {
    text: String,
    rows: Number,
    icon: Boolean,
  },
  data() {
    return {
      words: [],
      lines: [],
      prefs: {
        master: 0.3,
        tick: .06, // seconds
        delay: .004, // character ** 2 delay 
        rows: 3, // max rows before deletion
        punc: {
          '.': .1, // usually an abbreviation (not the end of the sentence (see _end))
          '%': .1,
          '"': .01,
          '”': .01,
          "'": .01,
          '’': .01,
          _default: .1,
          _end: .75,
        },
        lineheight: 0,
      },
      next: {
        tick: 0,
        line: 0,
      },
      timers: {
        tick: false,
      },
      debugDieNext: false,
    };
  },
  mounted() {
    this.restart(0);
  },
  computed: {
    getMaxRows() {
      return this.rows ? this.rows : this.prefs.rows;
    },
  },
  methods: {
    restart(iWait) {
      this.words = this.text.replace(/\n/g, ' __NEWLINE__ ').split(/\s+/);
      this.line = 0;
      this.lines = [];
      this.next.tick = 0;
      this.next.line = 0;
      this.newLine();
      window.setTimeout(this.tick, iWait * this.prefs.master);
    },
    tick() {
      if (this.timers.tick) {
        window.clearTimeout(this.timers.tick);
      }
      this.timers.tick = window.setTimeout(this.newWord, this.next.tick * 1000 * this.prefs.master);
    },

    newWord() {
      let word = this.words.shift();
      let peek = this.words[0];
      let mx;
      
      // console.log(`word: ${word}, peek: ${peek}`);

      if (!word) {
        window.setTimeout(this.finished, this.prefs.punc._end * 1000 * this.prefs.master);
        return;
      }

      if (word == '__NEWLINE__') {
        this.newLine(peek == '__NEWLINE__' ? ' ' : '');
        this.$forceUpdate();
        this.$nextTick(this.newWordOrNewLine);
        return;
      }

      this.lines[this.line].words.push(word);

      // modify the next tick..
      this.next.tick = this.prefs.tick;
      mx = word.match(/(\W)$/) || {};
      
      if (mx[0]) {
        if (this.prefs.punc[mx[0]]) {
          this.next.tick += this.prefs.punc[mx[0]];
        }
        this.next.tick += this.prefs.punc['_default'];
      }

      // slow for longer words...
      this.next.tick += (this.prefs.delay * (word.length ** 2));

      if (this.debugDieNext) {
        this.words = '';
        return;
      }

      // render #1
      this.$forceUpdate();
      this.$nextTick(this.newWordOrNewLine);
    },

    newLine(sFirstWord) {
      this.line = this.next.line;
      this.lines[this.line] = {
        words: sFirstWord ? [sFirstWord] : [],
        visible: true,
      };
      // next line will be +1
      this.next.line ++;
    },

    newWordOrNewLine() {

      // check line-height
      let ele = this.$el.querySelector(`[data-line="${this.line}"]`);
      // let parentRect = this.$el.getClientRects();
      let parentRect = this.$refs.subtitles;
      let charlieRect = this.$refs.charlie;

      // console.log(`RECT: `, parentRect, cremoveChildharlieRect);

      if (ele) {
        let rect = ele.getClientRects();
        
        // console.log('Rect: ', rect);
        
        let charlieComputed = getComputedStyle(charlieRect);
        // let subtitlesComputed = getComputedStyle(parentRect);

        let maxWidth = 
                parentRect.clientWidth 
                  // - parseFloat(subtitlesComputed.paddingLeft)
                  // - parseFloat(subtitlesComputed.paddingRight)
                  - charlieRect.clientWidth
                  - parseFloat(charlieComputed.marginLeft)
                  - parseFloat(charlieComputed.marginRight);
        
        // test for overflow... 
        if (rect[0].width >= maxWidth) {

          // remove the last word..  and put it back on the front of the stack
          let lastWord = this.lines[this.line].words.pop();
          this.words.unshift( lastWord );

          // start a new line
          this.newLine();
          this.$forceUpdate();
          // re-tick
          this.newWord();
        } else {
          this.tick();
        }
      }
    },

    finished() {
      // console.log('Subtitles: End');
      this.$emit('ends');
    },
  },
  watch: {
    text() {
      // this.restart(300);
    }
  },
  components: {
    Fragment
  },
}
</script>
<style lang="scss" scoped>

// no tailwind!?  // CLASS CONVERSION BY: https://tailwind-to-css.vercel.app
.xtw_outer {
  display: flex; 
  // margin-top: 1.5rem;
  // margin-bottom: 1.5rem; 
}

.xtw_wrap {
  display: none;
  // margin-right: 0.75rem; 
  // flex: none; 
  // border-radius: 0.5rem; 
  // height: 34px;
}

.xtw_inner {
  max-width: 100%;
  overflow-x: hidden; 
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; 
  // padding-left: 0.75rem;
  // padding-right: 0.75rem; 
  flex-wrap: wrap; 
  flex: 1 1 auto; 
  // border-radius: 0.25rem; 
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms; 
  // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); 
}

.xtw_line {
  box-sizing: border-box; 
  display: inline-block; 
  float: left; 
  clear: left; 
  white-space: nowrap; 
}

.xtw_word {
  display: inline-block; 
  padding-top: 0.125rem;
  padding-bottom: 0.125rem; 
  padding-left: 0.25rem; 
}

.fade-enter-from {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity .1s;
}
.fade-leave-active  {
  transition: opacity .05s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

</style>