<template>
  <div class="story-primary-wrapper">
    <div class="image">
      <img :src="story.image" :alt="story.name"/>
    </div>

    <div class="content">
      <h2>{{ story.name }}</h2>
      <p>{{ story.body }}</p>

      <div class="tags">
        <div class="tag" v-for="(topic, idx) in story.topics" :key="idx">
          <TopicTag :topic="topic" :mini="true"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['story'],

    mounted () {
      // console.log('Story', this.story)
    },
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/__2020_v3.scss';

  .story-primary-wrapper {
    display: flex;
    flex-direction: column;

    .image {
      width: 100%;
      height: 235px;
      cursor: pointer;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .content {
      margin-top: 0.6em;
      cursor: pointer;

      h2 {
        @include typo-size-headline;
        margin-bottom: 0.4em;
      }

      p {
        @include typo-size-body;
      }

      .tags {
        display: flex;
        margin-top: 1em;

        .tag {
          cursor: pointer;
        }

        .tag:not(:last-child) {
          margin-right: 1em;
        }
      }
    }

    // --------------------------=
    // Expanded state overrides 
    // --------------------------
    &.expanded {
      .image {
        height: 375px;
      }

      .content {
        padding: 1.5em;
        max-width: 80%;
        margin-top: -2em;
        background: $base-background;

        h2 {
          margin: 0;
        }

        h3 {
          @include typo-size-small;
          margin-top: 1.5em;

          .regular {
            font-family: $ff-body;
          }
        }
      }
    }
  }
</style>