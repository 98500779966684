<template>

	<footer class="container a-page e-footer" id="footer" v-if="showWebFooter">
		<div class="inner-background">

		<div class="container-upsell a-footer e-upsell" v-if="!loggedIn">
			<div class="a-upsell e-site-upsell" id="site-upsell">
				<div class="footer-upsell">
					<h3>We're building a new way to read the news. Sign up and start balancing your bias.</h3>
					<span v-on:click="showSignUp" class="btn btn-with-arrow">Sign Up</span>
				</div>
			</div>
		</div>

		<div class="container-inner a-footer e-links">
			<div class="a-links e-site-info-links" id="site-info-links">

				<div class="flex logo-wrapper">
					<base-svg svg="logo" height="1.75rem"/>
					<ul class="social-list right h-list">
						<li class="twitter">
							<a href="https://twitter.com/onesub_" target="_blank" title="Check out our Twitter!"></a>
						</li>

						<li class="facebook">
							<a href="https://www.facebook.com/justonesub" target="_blank" title="Check out our Facebook!"></a>
						</li>

						<li class="linkedin">
							<a href="https://www.linkedin.com/company/onesub" target="_blank" title="Check out our LinkedIn!"></a>
						</li>
					</ul>
				</div>

				<div class="flex footer-down">
					<div class="flex-footer-links">
						
							<ul class="footer-link h-list">

								<!-- <li>
									<router-link to="/" title="Stories">Stories</router-link>
								</li> -->
								<li>
									<a :href="`https://${documentURL}/mission/`" title="Our Mission">Mission</a>
								</li>
								<li>
									<a :href="`https://${documentURL}/pledges/`" title="Our Mission">Pledges</a>
								</li>
								<li>
									<a :href="`https://${documentURL}/contact/`" title="Get In Touch">Contact</a>
								</li>
								<li>
									<router-link to="/blog" title="Blog">Blog</router-link>
								</li>
								<!-- <li>
									<a :href="`https://intercom.help/onesub/en`" target="_blankup" title="Help & Support">Help</a>
								</li> -->
								<li>
									<a :href="`https://${documentURL}/privacy/`" title="Privacy Policy">Privacy</a>
								</li>
								<li>
									<a :href="`https://${documentURL}/terms/`" title="Terms & Conditions">Terms</a>
								</li>
								<li v-if="!loggedIn">
									<span v-on:click="showSignIn" title="Sign In">Sign In</span>
								</li>
								<li v-else>
									<span v-on:click="triggerSignOut" title="Sign Out">Sign Out</span>
								</li>
                <li>
									<a :class="{'pref-active' : (themeMode == 'lightmode')}" @click="setThemeMode('lightmode')">Light</a> | 
									<a :class="{'pref-active' : (themeMode == 'darkmode')}" @click="setThemeMode('darkmode')">Dark</a> | 
									<a :class="{'pref-active' : (themeMode == 'auto')}" @click="setThemeMode('auto')">Auto</a>
                </li>
								<li>
									<a href="javascript:document.location.reload(true);">Build - API:{{ build }} | App: {{ htmlBuild }}</a>
								</li>
							</ul>

						<!-- <div v-if="topics.length">
							<h2>Trending Topics</h2>
							<ul class="footer-link h-list">
								<li v-for="tag in topics.slice(0, 5)" :key="tag.code">
									<span v-on:click="emitClick(tag)" :class="{ 'router-link-exact-active' : checkActive(tag.code) }">{{ tag.name }}</span>
								</li>
							</ul>
						</div> -->
					</div>
					<div class="a-links e-site-copyright" id="site-copyright">
						<p>
							Piccadilly House, Bath, BA1 6PL / <a href="mailto:hello@onesub.io">hello@onesub.io</a><br/>
              &copy; OneSub - 2022 - All Rights Reserved
						</p>
					</div>
				</div>
			</div>
			</div>

		</div>

	</footer>
</template>

<script>

import API from "app/axios.js";


export default {
	name: 'Footer',
	data() {
		return {
			topics: []
		}
	},
	mounted() {
		let fetchArticles = API.get(`/topics/trending`);

		fetchArticles.then((response) => {
			this.topics = response.data;
		})
		.catch((error) => {
			// eslint-disable-next-line
			console.error(error);
		});
	},
	computed: {
		loggedIn() {
			return this.$store.getters.isLoggedIn;
		},
		documentURL() {
			return document.domain;
		},
    showWebFooter() {
      return this.$route?.meta?.display?.web_footer != 'hide';
    },
    themeMode() {
      return this.$userEngine.getPref('layout.theme');
    },
		build() {
			return this.$store.getters.getAccount?.build?.client;
		},
		htmlBuild() {
			return this.$store.getters.getHtmlBuild;
		},
	},
	props: {

	},
	methods: {
    setThemeMode(sMode) {
      this.$userEngine.setPref('layout.theme', sMode);
    },
		triggerSignOut() {
			if (localStorage) {
				localStorage.removeItem('token');
			}

			this.EventBus.$emit("appCallback", {
				type: 'deauthCallback',
				data: 'logout'
      });
      
      // Clear the store..
      this.$store.commit('unsetAccount');
			
			setTimeout(() => {
				window.location = `https://${this.documentURL}/r/x/logout`;
			}, 500);
		},
		emitClick(tag) {
			this.$router.push(`/topic/${tag.code}`).catch(() => {});
		},
		checkActive(item) {
			return item == this.$route.params.name
		},
		showSignUp() {
			this.$store.commit("updateShowModal", 'skip');
		},
		showSignIn() {
			this.EventBus.$emit('showLoginModal', 'clean');
		}
	}
};
</script>


<style lang="scss" scoped>
	@import '~styles/_variables';

	footer {
		background: $white-secondary;
		
		.darkmode & {
			background: #18191D;
		}

    .pref-active {
      text-decoration: underline !important;
    }

    font-family: $ff-tag !important;
		.inner-background {
			@include palette-block;
		}

		.container-upsell {
			padding: 3em 1em;
			text-align: center;
			background: rgb(241, 211, 2);
		}

		.container-upsell h3 {
			font-weight: bold;
			margin-bottom: 1em;
			margin-top: 0;
			line-height: 26px;
		}

		.container-upsell .btn {
			background: rgb(255, 255, 255)
		}

		.btn {
			cursor: pointer;
			font-size: 12px;
			color: rgb(16, 24, 24);
			border-radius: 0;
			border: 0;
			font-weight: bold;
			padding: .5em 55px .5em 15px;
			position: relative;
			transition: all .2s ease-in-out;
			border-radius: 2px;
			line-height: 2.3;
			display: inline-block;
			&:hover {
				box-shadow: 4px 4px 20px 0 rgba(0,0,0,0.08)
			}
		}

		.container-inner {
			max-width: 1100px;
			margin: 0 auto;
			padding: 50px 1em;
		}

		.flex {
			align-items: center;
			justify-content: space-between;
			> * {
				// flex: 1;
			}
		}

		.logo-wrapper {
			margin-bottom: 2em;
			align-items: center;
		}

		p {
			font-family: $ff-body;
			font-size: 12px;
			color: rgb(157, 169, 169);
			letter-spacing: 0;
			line-height: 24px
		}

		p a {
			color: rgb(157, 169, 169);
			transition: all 0.2s ease-in-out;
			&:hover {
				color: darken(#9da9a9, 30%);
			}

		}

    .h-list > li {
      float: left;
      margin-right: .5em;
    }

		.e-site-copyright {
			text-align: right;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			p:first-of-type {
				margin-top: 0;
			}

			p:last-of-type {
				margin-bottom: 0;
			}
		}

		.social-list {
			display: flex;
			justify-content: flex-end;
		}

		.social-list li {
			margin: 0;
			margin-left: 5px;
			float: left;
		}

		.social-list a {
			width: 32px;
			height: 32px;
			display: block;
			text-indent: -9999px;
			overflow: hidden;
			border-radius: 50%;
			transition: all 0.2s ease-in-out;
		}

		.footer-link li {
			padding: 0;
			// margin: 0;
			border: 0;
			text-transform: capitalize;
		}

		.footer-link li a {
			text-decoration: none;
		}

		.opacity-logo {
			justify-content: flex-start;
		}

		.flex-footer-links {
			display: flex;
      margin-top: auto;
		}

		.footer-down {
			align-items: initial;
		}

		.flex-footer-links div + div {
			margin-left: 8em;
		}

		.flex-footer-links li a,
		.flex-footer-links li span {
			opacity: .9;
			color: rgb(157, 169, 169);
			font-family: $ff-book; // ;
			font-size: 12px;
			font-weight: bold;
			line-height: 22px;
			cursor: pointer;
			transition: all 0.2s ease-in-out;
			&:hover {
				color: darken(rgb(157, 169, 169), 30%);
			}
		}

		.flex-footer-links h2 {
			opacity: .9;
			color: rgb(118, 129, 129);
			font-size: 14px;
			font-weight: bold;
			line-height: 17px;
			margin-bottom: .75em;
			margin-top: 0;
		}

		@media (min-width: 600px) {
			.footer-upsell {
				max-width: 1100px;
				margin: auto;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0 1em;
				h3 {
					margin-bottom: 0;
					flex: 1;
					text-align: left;
					padding-right: 1.5em;
				}
			}
		}

		@media(max-width: 600px) {

			.container-upsell {
				padding: 25px 1.5em;
			}

			.footer-upsell {
				h3 {
					font-size: 14px;
					line-height: 22px;
				}
			}

			.footer-down {
				flex-wrap: wrap;
				flex-wrap: wrap;
				justify-content: initial;
				align-items: initial;
				flex-direction: column;
				.flex-footer-links {
					width: 100%;
				}
			}

			.e-site-copyright {
				align-items: flex-start;
				text-align: left;
				margin-top: 2em;
			}
		}
	}
</style>
