<template>
	<section class="modal-outer" :class="`${type} ${mode}`" v-if="visible" v-on:mousedown.self="hideModal">
		<div class="modal-inner">
			<button class="modal-hide" v-if="closeable" v-on:click="hideModal">&times;</button>
			<slot></slot>
		</div>
	</section>
</template>

<script>

export default {
	name: 'Modal',
	props: {
		closeable: {
			type: Boolean,
			default: true
		},
		visible: {
			type: Boolean,
			default: false
		},
		type: {
			type: String,
			default: 'positive'
		},
	},
	mounted() {
		this.EventBus.$on('DOM-keydown-fire', (event) => {
			if(!this.modalClosed) {
				if(event.keyCode == 27) {
					this.$emit('hideModal', false);
				}
			}
		});
	},
	methods: {
		hideModal() {
			if(this.closeable) {
				this.$emit('hideModal', false);
			}
		},
		modalClosed() {
			const body = document.body;
			const scrollY = body.style.top;
			body.style.position = '';
			body.style.top = '';
			window.scrollTo(0, parseInt(scrollY || '0') * -1);
		},
		modalOpen() {
			const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
			const body = document.body;
			body.style.position = 'fixed';
			body.style.width = '100%';
			body.style.top = `-${scrollY}`;
		}
	},
	computed: {
		mode() {
			return 'dark';
		}
	},
	watch: {
		visible() {
			if(this.visible) {
				this.modalOpen();
			} else {
				this.modalClosed();
			}
		}
	}
}
</script>


<style lang="scss">

	@import '~styles/_variables';

	.negative {
		background: rgba(0,0,0,0.8);
	}

</style>
