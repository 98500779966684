import Vue from 'vue';
import store from "./store";
import router from './router'

/**
 *  Ascii
 *  https://patorjk.com/software/taag/#p=display&h=2&v=3&c=c%2B%2B&w=%20&f=ANSI%20Regular&t=Topic
 */


const coachingEngine = new Vue({
  router,
  store,

  data: function () {
    return {}
  },

  computed: {
    
  },

  methods: {
    debug(){},

    notice(){
      console.log(`\x1B[35mCoachingEngine --`, ...arguments);
    },

    error(){
      console.log(`\x1b[35mCoachingEngine --`, ...arguments);
    },

    fire(oPrefs) {
      let oMerge = {...{
        tab: `dashboard`,
        content: {
          dial: false, //or// `content`, // - Default is false, to show large dial above, set to `full`. To show dial smaller and left of content, set to `content`
          heading: ``, // - Don`t include to hide heading.
          text: ``, // - Don`t include to hide paragraph.
          cta: {
            text: ``, // - Button text. Don`t include to hide button.
            action: {
              type: `close`, // - No action needed if it`s just to close, will close by default, otherwise pass `route` to navigate to another route and close coaching.
              route: `` // - Set type to `route` and pass the route you want it to go.
            }
          },
          cancel: true,
        }
      }, ...oPrefs};
      console.log(oMerge);
      this.EventBus.$emit('coaching:show', oMerge);
    }
  }, // end methods

  created() {
    this.notice("Up and running... ");
    // JASON -- UNCOMMENT THIS TO DEBUG!
    // window.setTimeout(() => {
    //   this.fire({
    //     tab: `home`,
    //     content: {
    //       image: `swipe-skip-trans`,
    //       heading: `Swipe to Skip`,
    //       text: `Swipe stories that don't matter.<br/>Reach newsfeed-zero every day.`,
    //       ghost: `Awesome! I'll try that.`,
    //     },
    //   });
    // }, 1000);
  },

  watch: {
    // $route(to, from) {
    //   //
    // },
    // foo(to) {
    //   this.bar();
    // },
    // prefs: {
    //   deep: true,
    //   handler: function(to, from) {

    //   }
    // }
  }
})

Object.defineProperties(Vue.prototype, {
  $coachingEngine: {
    get: function () {
      return coachingEngine
    }
  }
})
