import { render, staticRenderFns } from "./ChallengesOverview.vue?vue&type=template&id=6944f394&scoped=true&"
import script from "./ChallengesOverview.vue?vue&type=script&lang=js&"
export * from "./ChallengesOverview.vue?vue&type=script&lang=js&"
import style0 from "./ChallengesOverview.vue?vue&type=style&index=0&id=6944f394&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6944f394",
  null
  
)

export default component.exports