<template>
	<div class="loader-wrapper">
		<div class="loader">
			<span :style="style" class="n1"></span>
			<span :style="style" class="n2"></span>
			<span :style="style" class="n3"></span>
			<span :style="style" class="n4"></span>
			<span :style="style" class="n5"></span>
		</div>
		<p v-if="showWaiter">Just a moment longer...</p>
	</div>
</template>

<script>

export default {
	name: 'Loader',
	props: {
		color: {
			type: String,
			default: "rgb(40, 110, 239, 0.5)",
		},
		size: {
			type: String,
			default: '15px'
		},
		showWaiter: {
			type: Boolean,
			default: false
		}
	},
	computed: {
		style() {
			return {
				width: this.size,
				height: this.size,
			}
		}
	}
}
</script>


<style lang="scss">

	@import '~styles/_variables';

	.loader {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.loader-wrapper p {
		text-align: center;
		animation: fadeInUp 0.2s ease-in-out;
		animation-delay: 5s;
		opacity: 0;
		animation-fill-mode: forwards;
	}

	@keyframes fadeInUp {
		0%{
			transform: translateY(10px);
			opacity: 0;
		}
		100% {
			transform: translateY(0);
			opacity: 1;
		}
	}

	.loader span {
    background: rgba($base-primary, 0.3);
		border-radius: $border-radius;
		animation-fill-mode: both;
		animation: loader .4s infinite ease-in-out;
		display: block;
		&.n1 { animation-delay: -0.16s; }
		&.n2 { animation-delay: -0.08s; }
		&.n3 { animation-delay: -0s; }
		&.n4 { animation-delay:  0.08s; }
		&.n5 { animation-delay:  0.16s; }
	}

	@keyframes loader {
		0%,
		80%,
		100% {
			transform: scale(0.6);
		}
		80% {
			transform: scale(0.2);
		}
	}


</style>
