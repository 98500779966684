<template>
	<transition name="fade" mode="out-in">
		<global-modal :visible="!modalClosed" v-on:hideModal="updateModalClose">

			<div class="modal-section" v-if="modalSection == 1" :key="1">
				<h2 v-if="getSignUpTestGroup == 'Post Click'">👋 Welcome back</h2>
				<h2 v-else>👋 Please consider signing up</h2>

				<p>OneSub is a new experiment and we would love your support.</p>
				<p><strong>Sign up for free</strong>, get personalised recommendations, manage your bias and help us build a news platform for the better.</p>

				<p class="flex">
					<button class="is-button" v-on:click="updateModalSection(2)">Sign Up For Free</button>
					<a class="is-button is-grey" :href="readArticleUrl" target="_blank" v-on:click="readArticle()" v-if="getSignUpTestGroup == 'Pre Click'">Read Article</a>
				</p>

				<p class="sign-off" v-if="getConfig.blogs['subscription-model']"><a :href="getConfig.blogs['subscription-model']">Find out how OneSub subscriptions will work</a></p>
			</div>

			<div class="modal-section" v-if="modalSection == 2" :key="2">
				<h2>👋 Well, hello there!</h2>

				<template v-if="innerSection == 1">
					<p>
            This will only take a sec and don't worry, we'll never give or sell your data to anyone else.  
            <!-- Signing up means you get a truly personal service. -->
          <!-- </p>
					<p> -->
            <span class="login-link" v-on:click="showLoginForm">Already signed up?</span>
          </p>
				</template>

				<!-- Error message -->
				<template v-if="throwResponseError">
					<span class="is-message is-error">{{responseErrorMessage}}</span>
				</template>

				<!-- Sign up form -->
				<form class="account-form">

					<div class="modal-first" v-if="innerSection == 1">
						<label for="firstName" class="text-label" :class="{ 'is-active' : focusElement == 'firstName' || account.details.firstName }">First Name</label>
						<input type="text" id="firstName" v-on:focus="toggleFocus('firstName')" v-model="account.details.firstName" v-on:blur="toggleFocus(false)">

						<label for="lastName" class="text-label" :class="{ 'is-active' : focusElement == 'lastName' || account.details.lastName }">Last Name</label>
						<input type="text" id="lastName" v-on:focus="toggleFocus('lastName')" v-model="account.details.lastName" v-on:blur="toggleFocus(false)">

						<label for="email" class="text-label" :class="{ 'is-active' : focusElement == 'email' || account.details.email }">Email</label>
						<input type="email" id="email" v-on:focus="toggleFocus('email')" v-model="account.details.email" v-on:blur="toggleFocus(false)">

						<label for="password" class="text-label" :class="{ 'is-active' : focusElement == 'password' || account.details.password }">New Password</label>
						<input type="password" id="password" v-on:focus="toggleFocus('password')" v-model="account.details.password" v-on:blur="toggleFocus(false)">


						<label class="checkbox-label">
							<input type="checkbox" v-model="account.details.terms">
							<p>I'm onboard with OneSub's <a :href="`https://${documentURL}/privacy`" target="_blank">privacy</a> & <a :href="`https://${documentURL}/terms`" target="_blank">terms</a>.</p>
						</label>

					</div>

					<div class="modal-second" v-if="innerSection == 2">

						<img height="1" width="1" style="display:none;" alt="" src="https://dc.ads.linkedin.com/collect/?pid=634946&conversionId=718706&fmt=gif" />
						<img height="1" width="1" style="display:none;" alt="" src="https://analytics.twitter.com/i/adsct?txn_id=o24vb&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0" />
						<img height="1" width="1" style="display:none;" alt="" src="//t.co/i/adsct?txn_id=o24vb&p_id=Twitter&tw_sale_amount=0&tw_order_quantity=0" />


						<label class="checkbox-label">
							<input type="checkbox" id="updatesEmailOn" :value="false" v-model="account.details.updatesEmailOn">
							<p>Send me story updates</p>
						</label>

						<div v-if="account.details.updatesEmailOn">
							<h2 class="inner-header">Email me stories</h2>
							<div class="flex-inner-options">
								<label class="checkbox-label no-margin" v-for="frequency in getNotifcations.frequency" :key="frequency.code">
									<input v-on:click="uncheck('updatesEmailFreq')" type="radio" :id="frequency.name" :value="frequency.id" v-model="account.details.updatesEmailFreq">
									<p>{{ frequency.name }}</p>
								</label>
							</div>

							<h2 class="inner-header">... in the</h2>
							<label class="checkbox-label no-margin" v-for="frequency in getNotifcations.timings" :key="frequency.code">
								<input v-on:click="uncheck('updatesEmailTiming')"  type="radio" :id="frequency.name" :value="frequency.id" v-model="account.details.updatesEmailTiming">
								<p>{{ frequency.name }} ({{frequency.note}})</p>
							</label>
						</div>

						<p class="inner-header">We're only a startup. May we also like to keep you updated on our progress?</p>

						<label class="checkbox-label">
							<input type="checkbox" v-model="account.details.updates">
							<p>I would like to receive OneSub development updates and news to my inbox.</p>
						</label>
					</div>

					<p class="flex-links" v-if="!showLoader">
						<!-- <button class="is-button is-back" v-if="innerSection == 2" v-on:click.prevent="returnForm">Back</button> -->
						<button class="is-button" v-on:click.prevent="submitForm">{{ formSubmitText }}</button>
					</p>

					<Loading v-else/>
				</form>

			</div>

			<div class="modal-section" v-if="modalSection == 3" :key="3">
				<h2>Thanks, {{account.details.firstName}}! 👍</h2>
				<p>You’re all set. Over time, our AI will start recommending articles you should read.</p>
				<p class="sign-off" v-if="getConfig.blogs['subscription-model']"><a :href="getConfig.blogs['subscription-model']">Find out how OneSub subscriptions will work</a></p>
				<p><button class="is-button" v-on:click="completeModal">Continue</button></p>
			</div>

		</global-modal>
	</transition>
</template>

<script>

import API from "app/axios.js";
// import axios from "axios";
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

export default {
	name: 'GlobalSignUp',
	data() {
		return {
			modalSection: 1,
			innerSection: 1,
			modalComplete: false,
			focusElement: false,
			showError: false,
			duplicateError: false,
			showAPIError: false,
			account: {
				firstName: '',
				lastName: '',
				email: '',
				password: '',
				terms: false,
				updates: false,
			},
			throwResponseError: false,
			responseErrorMessage: '',
			showLoader: false
		}
	},
	mounted() {
		this.EventBus.$on('DOM-keydown-fire', (event) => {
			if(!this.modalClosed) {
				if(event.keyCode == 27) {
					this.$store.commit('updateShowModal');
				}
			}
		});

		let urlParams = new URLSearchParams(window.location.search);

		// Let's check if the we're in the app. This is tested via ?mode=app on the domain.
		if (urlParams.get('showSignUp') == 'true') {
			this.$store.commit('updateShowModal', 'skip');
		}

		if (urlParams.get('showLogIn') == 'true') {
			this.showLoginForm();
		}



		if(this.isLoggedIn) {
			setTimeout(() => {
				if(this.$route.path == "/account" || this.$route.path == "/account/") {
					return;
				}
				let urlParams = new URLSearchParams(window.location.search);	
				if(urlParams.get('redirect') && urlParams.get('redirect').startsWith("/")) {
					this.$router.push(urlParams.get('redirect'));
				}
			}, 500);
		} else {
			setTimeout(() => {
				if (this.$route.path == '/login' || this.$route.path == '/login/') {
					this.showLoginForm();
				}
			}, 500);
		}
	},
	computed: {
		formSubmitText() {
			return (this.innerSection == 1) ? 'Next' : 'Set Preferences';
		},
		documentURL() {
			return document.domain;
		},
		difference() {
			return dayjs(this.storeArticleTime.outbound).from(dayjs(this.storeArticleTime.inbound), true) // 2 years ago
		},
		storeArticleTime() {
			return this.$store.getters.getArticleTime;
		},
		getConfig() {
			return this.$store.getters.getConfig;
		},
		isLoggedIn() {
			return this.$store.getters.isLoggedIn;
		},
		storeAccount() {
			return this.$store.getters.getAccount;
		},
		modalClosed() {
			return !this.$store.getters.showAccountModal;
		},
		modalType() {
			return this.$store.getters.showAccountModal;
		},
		getSignUpTestGroup() {
			return this.$store.getters.getSignUpTestGroup;
		},
		readArticleUrl() {
			return this.$store.getters.articleURL;
		},
		getNotifcations() {
			return this.$store.getters.getConfig.account.notif.email;
		},
		getArticle() {
			if(this.$route.params.article) {
				return this.$store.getters.getArticle(this.$route.params.article) ? this.$store.getters.getArticle(this.$route.params.article) : '';
			} else if(this.$route.params.story && this.readArticleUrl) {
				let story = this.$store.getters.getStory(this.$route.params.story);

				if (story && story.links) {
					let slugs = Object.keys(story.links);
					for (let i=0; i<slugs.length; i++) {
						let slug = slugs[i];

						let link = story.links[slug];

						if (link.url == this.readArticleUrl) {
							return link;
						}
					}
				}
			} else if(this.$route.params.story) {
				return this.$store.getters.getStory(this.$route.params.story) ? this.$store.getters.getStory(this.$route.params.story).links[0] : '';
			}

			return '';
		}
	},
	methods: {
		uncheck(type) {
			if(this.account.details[type]) {
				this.$delete(this.account.details, type);
			}
			if(type == 'updatesEmailOn') {
				this.$delete(this.account.details, 'updatesEmailFreq');
				this.$delete(this.account.details, 'updatesEmailTiming');
			}
		},
		updateModalSection(page) {
			this.modalSection = page;
		},
		toggleFocus(element) {
			this.focusElement = element;
		},
		showLoginForm() {
			this.EventBus.$emit('showLoginModal');
			this.updateModalClose();
		},
		returnForm() {
			this.innerSection = 1;
			this.showError = false;
			this.duplicateError = false;
			this.showAPIError = false;
		},
		submitForm() {
			this.showLoader = true;
			this.throwResponseError = false;
			let urlParams = new URLSearchParams(window.location.search);
			let internalTest = false;

			if (urlParams.get('redirect')) {
				if (urlParams.get('redirect').startsWith("/")) {
					// console.log("Internal URL detected")
					internalTest = true;
				}
			}

			if (this.innerSection == 1) {

				if (this.account.details.terms &&
					this.account.details.firstName &&
					this.account.details.lastName &&
					this.account.details.password &&
					this.account.details.email) {

					// this.$store.commit('updateAccount', this.account);
					API.post(`/account`, this.account.details).then((response) => {
						this.showLoader = false;
						
						if (response.status.toString().substring(0,1) == 2) {
							this.$store.commit("updateAccount", response.data);
							this.innerSection = 2;
						}
						
						// Plausible tracking: (User account created)
						this.EventBus.$emit('track:event', {
							label: 'SignUp: Initial',
							once: true, // default
						});
					}).catch((error) => {
						this.showLoader = false;
						this.handleResponseError(error);
					});

				}

			} else if (this.innerSection == 2) {
				if (this.account.details.updatesEmailOn) {
					if (!this.account.details.updatesEmailFreq || !this.account.details.updatesEmailTiming) {
						return;
					}
				}

				// this.$store.commit('updateAccount', this.account);
				API.patch(`/account`, this.account.details).then((response) => {
					this.showLoader = false;

					if (response.status.toString().substring(0,1) == 2) {
						this.$store.commit("updateAccount", response.data);
						this.modalSection = 3;
            
						if (this.account.details.updatesEmailOn) {
							// Plausible tracking: (User set email-digests on)
							this.EventBus.$emit('track:event', {
								label: 'SignUp: Emails',
								once: true, // default
							});
						}
            
						// Plausible tracking: (User completed 'settings' step)
						this.EventBus.$emit('track:event', {
							label: 'SignUp: Settings',
							once: true, // default
						});

						this.EventBus.$emit("appCallback", {
							type: 'authCallback',
							data: {
								mode: 'signUp',
								response: response.data
							}
						});

						if (internalTest) {
							this.$router.push(urlParams.get('redirect'));
							console.error("pushing to", urlParams.get('redirect'));
						}
					}

				}).catch((error) => {
					this.showLoader = false;
					this.handleResponseError(error);
				});
			}
		},
		completeModal() {
			this.modalComplete = true;
			this.$store.commit('updateShowModal');
		},
		updateModalClose() {
			this.modalSection = 1;
			this.$store.commit('updateShowModal');
		},
		readArticle() {
			this.trackArticleOutbound();
		},

		trackArticleOutbound() {

			let article = this.getArticle;
			let payload = {
				article: article,
				articlePublisher: article.publisher.name,
				story: this.$route.params.story,
			};

			this.EventBus.$emit('trackArticleAction', payload);

			if(this.isLoggedIn) {
				this.$store.commit('storeArticleTime', {type: 'inbound', time: false});
				this.$store.commit('storeArticleTime', {type: 'outbound', time: new Date()});
			}
		},

		handleResponseError(error) {
			switch (error.response.data.error.reason) {
				case 'exists':
					this.responseErrorMessage = `It looks like you're already signed up.  Please sign in.`;
					break;
				case 'email':
					this.responseErrorMessage = `That email address doesn't seem valid. Please check it and try again.`;
					break;
				case 'password-length':
					this.responseErrorMessage = `We couldn't accept that password.  Please provide a password at least 10 characters long.`;
					break;
				case 'missing-fields':
					this.responseErrorMessage = 'It looks like some information was missing. Please check and try again.';
					break;
				default:
					this.responseErrorMessage = `Sorry, something went wrong. We we're unable to sign you up. Please try again.`;
					break;
			}

			this.throwResponseError = true;
		}
	},
	watch: {
		storeAccount: {
			handler: function(newValue)  {
				if(newValue) {
					this.account = newValue;
				}
			},
			deep: true,
			immediate: true
		},
		modalType: {
			handler: function(newValue) {
				if(newValue == "skip") {
					this.modalSection = 2;
				}
			},
			immediate: true
		},
    modalClosed(toClosed, fromClosed) {
      // tracking a "closed" flag so invert
      let bOpen = (toClosed == false) && (fromClosed == true);
      if (bOpen) {
        // Plausible tracking: (User considered signup)
        this.EventBus.$emit('track:event', {
          label: 'SignUp: Show',
          once: true, // default
        });
      }
    },
	}
};
</script>

<style lang="scss" scoped>

	.is-grey,
	.is-button.is-back {
		background: rgb(204, 204, 204);
		padding-right: 1.25em !important;
		background-image: none !important;
	}

	.flex-links {
		display: flex;
		align-items: center;
		justify-content: space-between;
		span {
			cursor: pointer;
		}
		margin-top: 2em;
	}

	.login-link {
		cursor: pointer;
		text-decoration: underline;
	}

	.inner-header {
		font-size: 14px;
		margin-top: 2em;
	}

	.no-margin {
		margin-top: 0 !important;
		margin-bottom: 0 !important;
	}


	.extra-margin {
		margin-top: 2em !important;
	}

	.flex-inner-options {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		> * {
			width: 50%;
		}
	}

</style>
