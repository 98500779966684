<template>
  <Modal :visible="visible" @close="close()" v-if="getGoal">
    <div class="goal-container">
      <dial size="static" class="dial-fill"/>

      <div class="content">
        <h2>{{ getGoal.heading }}</h2>
        <p>{{ getGoal.copy }}</p>
      </div>

      <button @click="handleTrigger(getGoal.actions.primary.trigger)">{{ getGoal.actions.primary.button }}</button>
      
      <button class="ghost" v-if="getGoal.actions.secondary" @click="handleTrigger(getGoal.actions.secondary.trigger)">{{ getGoal.actions.secondary.button }}</button>
    </div>
  </Modal>
</template>

<script>
  import Modal from 'app/components/_v6/modal/base/Modal.vue' 

  export default {
    data() {
      return {
        visible: false,
        state: null,
        goals: {
          'section-complete': {
            heading: 'Huzzah!',
            copy: `You've completed the Headlines section! You're making progress on your daily goal.`,
            button: `I'm ready, next section`,
            action: '',
            actions: {
              primary: {
                button: `I'm ready, next section`,
                trigger: 'next-section',
              },
            }
          },
          'progress': {
            heading: `Don't lose your progress`,
            copy: `You've read your first story, well done you! Create your free OneSub account and keep your streak going.`,
            actions: {
              primary: {
                button: `Sure, let's do it!`,
                trigger: 'sign-up',
              },
              secondary: {
                button: 'Not yet, next section please',
                trigger: 'next-section',
              },
            }
          },
          'first-story': {
            heading: 'Nice work!',
            copy: `You've read your first story, well done you! There's more to discover next on the OneSub.`,
            actions: {
              primary: {
                button: 'Great, show me!',
                trigger: 'homepage',
              },
            }
          }
        }
      }
    },

    components: {
      Modal,
    },

    computed: {
      getGoal() {
        return this.goals[this.state]; 
      }
    },

    mounted () {
      this.EventBus.$on('modal:goal', (goal) => {
        this.state = goal;
        this.visible = true;
      });
    },

    methods: {
      close() {
        this.visible = false;
      },

      handleTrigger(trigger) {
        switch (trigger) {
          case 'next-section': 
            this.close();
            return;
          case 'sign-up':
            this.close();
            this.$store.commit("updateShowModal", 'skip');
            return;
          case 'homepage':
            this.close();
            this.$router.push('/');
            return;
          default: return;
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/__2020_v3.scss';

  .goal-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 2rem 0;
      
      h2 {
        font-size: 38px;
        line-height: 100%;  
        font-weight: bold;
        margin-bottom: 1rem;
      }

      h2, p {
        text-align: center;
      }
    }

    .ghost {
      margin-top: 3rem;
    }
  }
</style>