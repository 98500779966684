<template>
  <div class="osx-threads-overlay" v-on:click="close()">
    <div class="wrapper" v-on:click.stop>
      <iframe v-if="frame" class="frame" :src="frame"/>
      <div v-else>
        <input type="text" v-model="link" placeholder="Paste an article link here..." v-on:paste="goPaste()" v-on:keyup.enter="goLink()">
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'HandleThreadsOverlay',
    data() {
      return {
        link: '',
        thread: '',
        story: '',
        frame: '',
        base: '/share?scope=web',
      }
    },
    mounted() {
      // console.log('Mounting ThreadsOverlay : ', this.threadShareOverlay);
      this.$el.querySelector('input').focus();
      this.thread = this.threadShareOverlay?.thread;
      this.story = this.threadShareOverlay?.story;
      if (this.threadShareOverlay?.link || this.story) {
        this.link = this.threadShareOverlay?.link || '';
        this.goLink();
      }
    },
    computed: {
      threadShareOverlay() {
        let threadShareOverlay = this.$store.getters.getThreadShareOverlay || {};
        return threadShareOverlay.on ? threadShareOverlay : false;
      }
    },
    methods: {
      goLink(){
        if (!this.story && (this.link && !this.link?.match(/^https?:\/\//))) {
          this.$el.querySelector('.wrapper').classList.add('shake');
          window.setTimeout(() => {
            this.$el.querySelector('.wrapper').classList.remove('shake');
          }, 1000);
        } else {
          window.open(`${this.base}&link=${this.link}&thread=${this.thread}&story=${this.story}`, '_blank').focus();
          this.EventBus.$emit('thread:close-overlay');
          // this.frame = `${this.base}&link=${this.link}&thread=${this.thread}&story=${this.story}`;
        }
      },
      goPaste() {
        window.setTimeout(this.goLink, 100);
      },
      close(){
        this.EventBus.$emit('thread:close-overlay');
      },
      onkey(e){
        if (e.code == 'Escape' || e.keyCode == 27) {
          this.close();
        }
      }
    },
    created: function () {
      window.addEventListener('keydown', this.onkey)
    },
    beforeDestroy: function () {
      window.removeEventListener('keydown', this.onkey)
    },
  }
</script>

<style scoped lang="scss">
  // very explicitly named in case we translate across 3rd party domains
  .osx-threads-overlay {
    z-index: 9999999999;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    display: flex;
    justify-content: center;
    backdrop-filter: blur(10px) brightness(40%) grayscale(75%);
    transition: .6s;

    .wrapper {
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      box-shadow: 5px 5px 20px rgba(0,0,0,.5);
      transition: .6s;
      padding: 0;
      margin-bottom: -5px;
      background: #fafafa;

      @media(max-width: 670px) {
        border-radius: 0;
      }

      .frame {
        min-width: 400px;
        min-height: 75vh;
        border-radius: 5px;
        border: 0;
        width: 100%;
        height: 100%;

        @media(max-width: 670px) {
          width: 100vw;
          height: 100vh;
          border-radius: 0;
        }
      }

      input, input[type="text"] {
        padding: 1em;
        min-width: 400px;
        border: 0;
      }

      &.shake {
        animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;
      }

    }
  }

  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }

    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }

    40%, 60% {
      transform: translate3d(4px, 0, 0);
    }
  }
</style>