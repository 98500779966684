<template>
  <div class="story-secondary-wrapper">
    <!-- <div class="base-tag"><span class="regular">Reported</span> 2 days ago</div> -->

    <div class="inner-primary">
      <div class="content">
        <h2>{{ story.name }}</h2>
        <p>{{ story.synopsis }}</p>

        <div class="tags">
          <div class="tag" v-for="(topic, idx) in story.topic" :key="idx">
            <TopicTag :topic="topic" :mini="true"/>
          </div>
        </div>
      </div>

      <div class="image" v-if="story.image && !hideImage">
        <img :src="story.image" :alt="story.name"/>
      </div>
    </div>
  
    <!-- <div class="statements" v-if="statements">
      <div class="statement" v-for="(statement, idx) in statements" :key="idx">
        <h3>{{ statement.heading }}</h3>
      </div>
    </div> -->
  </div>
</template>

<script>
  export default {
    props: ['story', 'expanded', 'hideImage'],
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/__2020_v3.scss';

  .story-secondary-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .base-tag {
      @include base-tag;
      margin-bottom: 0.6em;
    }

    .inner-primary {
      display: flex;
      
      .image {
        width: 65px;
        height: 65px;
        cursor: pointer;
        
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .content {
        flex: 1;
        margin-right: 1em;
        cursor: pointer;

        h2 {
          @include typo-size-heading;
          margin-bottom: 0.4em;
        }

        p {
          @include typo-size-body;
        }

        .tags {
          display: flex;
          margin-top: 1em;

          .tag {
            cursor: pointer;
          }

          .tag:not(:last-child) {
            margin-right: 1em;
          }
        }
      }
    }

    .statements {
      margin-top: 1.5em;

      .statement {
        border-left: 4px solid $yellow-tone-3;
        padding-left: 1em;

        h3 {
          @include typo-size-body;
        }
        
        &:not(:last-child) {
          margin-bottom: 0.8em;
        }
      }
    }

    // --------------------------=
    // Expanded state overrides 
    // --------------------------
    &.expanded {
      .image {
        width: 100%;
        height: 375px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .content {
        padding: 1.5em;
        max-width: 80%;
        margin-top: -2em;
        background: $base-background;

        h2 {
          margin: 0;
        }

        h3 {
          @include typo-size-small;
          margin-top: 1.5em;

          .regular {
            font-family: $ff-body;
          }
        }
      }
    }
  }
</style>