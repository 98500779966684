<template>
  <div v-if="isAlpha">
    <div v-on:click="toggleChallenge" class="challenges-notification" xxv-if="!showMenu">
      <global-svg type="challenges" height="16px" color="#FFF"></global-svg>
      <div class="challenges-count">1</div>
    </div>

    <div @click="toggleChallenge" v-if="showChallenge" class="challenges-overview">
			<div class="challenge-icon">
				<global-svg type="challenges" height="16px" color="#18191D"></global-svg>
			</div>

			<div class="challenge-content">
        <div class="challenge-heading">
          <h3>Read your first story</h3>
          <global-svg type="arrow" color="#18191D" width="11px" height="11px"></global-svg>
        </div>
				<p>Choose a story and read it in full to complete this challenge.</p>
			</div>

      <div class="challenge-tags">
        <div class="inner-tag">Challenge</div>
      </div>
		</div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        showChallenge: false,
      }
    },

    computed: {
      isAlpha () {
        return Boolean(this.$store.getters.isAlpha);
      },
    },

    methods: {
      toggleChallenge() {
        this.showChallenge = !this.showChallenge;
      },
    },
  }
</script>

<style lang="scss" scoped>
	@import '~styles/__2020_v3.scss';

  .challenges-notification {
		position: relative;

		.challenges-count {
			position: absolute;
			top: 0;
			right: 0;
			margin-top: -5px;
			margin-right: -5px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 13px;
			height: 13px;
			background: $yellow-tone-3;
			color: $yellow-tone-7;
			font-size: 0.6em;
			border-radius: 50%;
		}
	}

  .challenges-overview {
		display: flex;
		align-items: center;
		position: fixed;
		top: 55px;
    left: 0;
    width: 100%;
		background: $white-primary;
		height: auto;
		padding: 0.8em 1em;
		z-index: 30;
		border-bottom: 2px solid $yellow-tone-3;
		@include layer-box;

		.challenge-icon {
			flex: unset;
			margin-right: 1em;
		}

		.challenge-content {
			display: flex;
			flex-direction: column;
			align-items: flex-start;

			.challenge-heading {
        display: flex;
        align-items: center;

        h3 {
          @include typo-size-heading;
          margin: 0;
          margin-right: 0.5em;
        }
      }

			p {
				@include typo-size-body-small;
				line-height: 1.2;
				margin: 0;
				margin-top: 0.5em;
			}
		}

    .challenge-tags {
      position: absolute;
      top: 4px;
      right: 4px;
      display: flex;
      margin-bottom: 0.6em;

      .inner-tag {
        flex: unset;
        padding: 0.5em;
        background: $yellow-tone-3;
        border-radius: $el-border-radius;
        @include typo-size-small;

        &:not(:last-child) {
          margin-right: 0.3em;
        }
      }
    }
	}
</style>