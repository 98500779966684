<template>
    <div v-if="messages > 0">
        <span class="new-message">
          <span class="numeral">{{ messages }}</span>
          <span class="reckoner">{{messages | plural}}</span>
        </span>
    </div>
</template>

<script>

export default {
  name: 'NewMessagePartial',
  props: {
      messages: {
        type: Number,
      },
      mode: {
        type: String,
      }
  },
  filters: {
    plural: function (value) {
        if (value > 1) return 'Messages'
        return 'Message'
    }
  }
}
</script>


<style lang="scss">

	@import '~styles/_variables';

	.new-message {
		background: $highlight-primary;
		padding: 5px 8px;
		border-radius: $border-radius;

    .desktop & {
      display: inline-block;
      position: relative;
      
      top: -9px;
      left: -9px;
      padding: 0px 4px;
      border-radius: 50%;
      font-size: 9px;
      color: $black;
      width: 13px;
      height: 13px;

      .reckoner {
        display: none;
      } 
    }
	}

  

</style>
