<template>
  <transition name="wipe-up">
    <div class="excluded-background" v-if="checkiOSApp && showOverlay">
        <div class="excluded-wrapper">
            <div class="intro">
                <h2>Customise your experience</h2>
                <p>OneSub understands the news and how it’s written. We will help you read broad range of opinions on subjects and help you discover important stories from around the word.</p>
            </div>
            <div class="select-sources">
                <!-- <p class="bold">Select sources</p> -->
                <div class="options">
                    <label class="checkbox-label">
                        <input type="checkbox" @change="updateRecommendedCheckboxSelection" :checked="recommendedSelected == true ? true: false">
                        <p class="bold">Select all recommended sources</p>
                    </label>
                </div>
            </div>
            <div class="available-publishers-wrapper">
                <div class="available-publishers">
                    <label class="checkbox-label custom" v-for="publisher in fetchPublishers" :key="publisher.code">
                        <input type="checkbox" @change="didSelectPublisher(publisher.code, $event)" :checked="isChecked(publisher.code) == true ? true: false">
                        <p class="regular">{{publisher.name}}</p>
                    </label>
                </div>
            </div>
            <div class="action">
                <button v-if="!completedExcludedStep" v-on:click="confirmExcludedPublishers()" class="is-button no-arrow">Got it, thanks!</button>
                <div class="action-group" v-if="completedExcludedStep">
                    <button v-on:click="confirmExcludedPublishers()" class="is-button no-arrow">Update</button>
                    <button v-on:click="closeOverlay()" class="ghost-btn">Close</button>
                </div>
            </div>
        </div>
    </div>
  </transition>
</template>

<script>
    import API from "app/axios.js";

    export default {
        data() {
            return {
                showOverlay: false,
                recommendedSelected: true,
                selectedPublishers: [],
            }
        },
        mounted () {
            this.EventBus.$on("handleExcludedSettings", event => {
                if (!event.showExcluded) {
                    this.showOverlay = false;
                } else if (event.showExcluded) {
                    // only fetch if required, not on *every* request (inc desktop)
                    API.getPublishers();
                    // turn on, the getter will populate when the fetch completes
                    this.showOverlay = true;
                }
                this.checkRelevantCheckbox();
            });
            this.selectedPublishers = this.$store.getters.getExcludedPublishers;
        },
        computed: {
            isLoggedIn() {
                return this.$store.getters.isLoggedIn;
            },
            checkiOSApp() {
                return this.$store.getters.hasIOSApp;
            },
            completedExcludedStep() {
                if (this.$store.getters.completedExcludedStep === 'not-defined') {
                    return false;
                }
                return this.$store.getters.completedExcludedStep;
            },
            fetchPublishers() {
                return this.$store.getters.getPublishers;
            },
        },
        methods: {
            deviceMessage(sMode, oData){
                this.EventBus.$emit("appCallback", {
                    type: sMode,
                data: oData || {}
            });
            },
            
            deviceLog(sMessage){
                this.deviceMessage('consoleXCode',{
                    message: sMessage,
                });
            },

            updateRecommendedCheckboxSelection() {
                this.recommendedSelected = !this.recommendedSelected;
                this.selectedPublishers = this.selectedPublishers.splice(0, this.selectedPublishers.length);
                this.deviceLog(this.recommendedSelected);

                if (!this.recommendedSelected) {
                    let publishers = this.$store.getters.getPublishers;
                    Object.values(publishers).forEach(publisher => {
                        this.selectedPublishers.push(publisher.code);
                    });
                }

                if (this.recommendedSelected) {
                    this.selectedPublishers = [];
                }
            },

            isChecked(code) {
                if (this.selectedPublishers.indexOf(code) > -1) {
                    return false;
                }

                return true;
            },

            didSelectPublisher(publisherCode, event) {
                if (!event.target.checked) {
                    this.selectedPublishers.push(publisherCode);
                } else if (event.target.checked) {
                    this.selectedPublishers.splice(this.selectedPublishers.indexOf(publisherCode), 1);
                }
            },

            confirmExcludedPublishers() {
                if (this.isLoggedIn) {
                    this.deviceLog('Excluding publishers: ' + JSON.stringify(this.selectedPublishers));
                    API.patchExcludedPublishers(this.selectedPublishers);
                    this.$store.commit('updateExcludedPublishers', this.selectedPublishers);
                } else {
                    this.deviceLog('Excluding publishers: ' + JSON.stringify(this.selectedPublishers));
                    localStorage.setItem('ios_publisher_exclusion_flow_completed', true);
                    localStorage.setItem('excluded_publishers', JSON.stringify(this.selectedPublishers));
                }
                this.showOverlay = false;
            },

            checkRelevantCheckbox() {
                if (this.$store.getters.completedExcludedStep) {
                    const excluded = this.$store.getters.getExcludedPublishers;
                    if (excluded.length > 0) {
                        this.recommendedSelected = false;
                    } else {
                        this.recommendedSelected = true;
                    }
                }
            },

            closeOverlay() {
                this.showOverlay = false;
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import "~styles/_variables";

    .wipe-up-enter-active {
        transition: all .3s ease;
    }

    .wipe-up-leave-active{
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .wipe-up-enter, .wipe-up-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */ {
        transform: translateY(100vh);
        opacity: 0;
    }

    .excluded-background {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 600;
        background: $background-faded;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .excluded-wrapper {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: $base-primary;
        padding: 20px;
        z-index: 700;

        h2, p {
            color: $white-primary;
            margin-top: 0;
        }

        .intro {
            display: flex;
            flex-direction: column;
            text-align: center;

            h2 {
                font-weight: bold;
                font-size: 20px;
            }

            p {
                font-size: 15px;
                line-height: 1.4;
                margin-bottom: 0;

                &.bold {
                    font-weight: bold;
                    color: red;
                    text-align: left;
                }
            }
        }

        .select-sources {
            width: 100%;
            padding-top: 20px;

            p {
                font-size: 15px;
                line-height: 1.4;
                margin-bottom: 0;

                &.bold {
                    text-align: center;
                    font-weight: bold;
                }
            }

            .options {
                .checkbox-label {
                    text-align: left;

                    p {
                        font-size: 14px;

                        &.bold {
                            font-weight: bold;
                            text-align: left;
                        }

                        &.regular {
                            font-weight: normal;
                        }
                    }
                }
            }
        }

        .available-publishers-wrapper {
            display: flex;
            flex: 1;
            position: relative;
            height: 100%;
            width: 100%;
            margin-left: 30px;
            overflow-y: scroll;
            
            .available-publishers {
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                height: auto;
            }
        }

        .checkbox-label {
            margin-top: 0.5em;
        }

        .action {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            margin-bottom: 10px;
            width: 100%;

            .action-group {
                display: flex;
                flex-direction: column;
                width: 100%;

                .ghost-btn {
                    color: $white-primary-faded;
                    font-weight: bold;
                    font-size: 12px;
                    margin-top: 15px;
                    text-decoration: none;
                    border: none;
                    outline: none;
                    background: transparent;
                }
            }
        }

        .is-button {
            width: 100%;
            padding: 1.2em;
        }

        input[type="checkbox"] {
            padding: 0.5em;
            background-color: transparent;
        }
    }
</style>