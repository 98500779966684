<template>
	<div v-if="type === 'share'">
		<ul class="flex-share">

			<!-- <li class="facebook" v-on:click="clickItem('facebook-chart')">
				<svg width="6px" height="10px" viewBox="0 0 6 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<g id="onesub/story/article-alternative-desktop" transform="translate(-687.000000, -577.000000)" fill="#FFFFFF">
							<g id="Group-9" transform="translate(676.000000, 568.000000)">
								<g id="Group">
									<path d="M12.5039062,19 L12.5039062,14.5273438 L11,14.5273438 L11,12.75 L12.5039062,12.75 L12.5039062,11.34375 C12.5039062,10.6015588 12.7122375,10.0253927 13.1289062,9.61523438 C13.545575,9.20507607 14.0989549,9 14.7890625,9 C15.3489611,9 15.8046857,9.02604141 16.15625,9.078125 L16.15625,10.6601562 L15.21875,10.6601562 C14.8671857,10.6601562 14.6263027,10.7382805 14.4960938,10.8945312 C14.3919266,11.0247402 14.3398438,11.2330715 14.3398438,11.5195312 L14.3398438,12.75 L16,12.75 L15.765625,14.5273438 L14.3398438,14.5273438 L14.3398438,19 L12.5039062,19 Z" id=""></path>
								</g>
							</g>
						</g>
					</g>
				</svg>
				<span class="social-label">Share on Facebook</span>
			</li> -->

			<li class="twitter" v-on:click="clickItem('twitter-chart')">
				<svg width="11px" height="9px" viewBox="0 0 11 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<defs></defs>
					<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<g id="onesub/story/article-alternative-desktop" transform="translate(-718.000000, -578.000000)" fill="#FFFFFF">
							<g id="Group-9" transform="translate(676.000000, 568.000000)">
								<g id="Group-8" transform="translate(33.000000, 0.000000)">
									<path d="M18.8613281,12.234375 C18.8756511,12.291667 18.8828125,12.384765 18.8828125,12.5136719 C18.8828125,13.544927 18.632164,14.5403598 18.1308594,15.5 C17.6152318,16.5169322 16.8919317,17.3261689 15.9609375,17.9277344 C14.9440053,18.6009148 13.7766993,18.9375 12.4589844,18.9375 C11.1985614,18.9375 10.0455781,18.6009148 9,17.9277344 C9.15755287,17.9420574 9.33658754,17.9492188 9.53710938,17.9492188 C10.5826875,17.9492188 11.5208292,17.6269563 12.3515625,16.9824219 C11.8502579,16.9824219 11.4098326,16.8356134 11.0302734,16.5419922 C10.6507142,16.2483709 10.3893236,15.8795595 10.2460938,15.4355469 C10.3893236,15.4498699 10.52539,15.4570312 10.6542969,15.4570312 C10.8548187,15.4570312 11.0553375,15.4355471 11.2558594,15.3925781 C10.7402318,15.2779942 10.3105486,15.0130229 9.96679688,14.5976562 C9.62304516,14.1822896 9.45117188,13.7096381 9.45117188,13.1796875 L9.45117188,13.1367188 C9.76627762,13.3229176 10.1028628,13.423177 10.4609375,13.4375 C10.1601547,13.2226552 9.9166676,12.9505225 9.73046875,12.6210938 C9.5442699,12.291665 9.45117188,11.930015 9.45117188,11.5361328 C9.45117188,11.1422506 9.55859268,10.7662778 9.7734375,10.4082031 C10.332034,11.1100296 11.0159465,11.6686177 11.8251953,12.0839844 C12.6344442,12.499351 13.4973912,12.7285154 14.4140625,12.7714844 C14.3854165,12.5996085 14.3710938,12.4277352 14.3710938,12.2558594 C14.3710938,11.8548157 14.4713532,11.4788429 14.671875,11.1279297 C14.8723968,10.7770165 15.1445295,10.5013031 15.4882812,10.3007812 C15.832033,10.1002594 16.2044251,10 16.6054688,10 C16.9348975,10 17.2392564,10.0644525 17.5185547,10.1933594 C17.797853,10.3222663 18.0449208,10.4941396 18.2597656,10.7089844 C18.7753932,10.6087235 19.2552061,10.4296888 19.6992188,10.171875 C19.5273429,10.7018256 19.1979191,11.117186 18.7109375,11.4179688 C19.1406271,11.3606768 19.5703104,11.2389332 20,11.0527344 C19.6848943,11.51107 19.3053408,11.9049463 18.8613281,12.234375 Z" id=""></path>
								</g>
							</g>
						</g>
					</g>
				</svg>
				<span class="social-label">Share on Twitter</span>
			</li>

			<li class="linkedin" v-on:click="clickItem('linkedin-chart')">
				<svg width="11px" height="11px" viewBox="0 0 10 11" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<path d="M1.20535714,2.78571429 C1.5327381,2.78571429 1.81547619,2.66666667 2.05357143,2.42857143 C2.29166667,2.19047619 2.41071429,1.9077381 2.41071429,1.58035714 C2.41071429,1.25297619 2.29166667,0.970238095 2.05357143,0.732142857 C1.81547619,0.494047619 1.5327381,0.375 1.20535714,0.375 C0.87797619,0.375 0.595238095,0.494047619 0.357142857,0.732142857 C0.119047619,0.970238095 0,1.25297619 0,1.58035714 C0,1.9077381 0.119047619,2.19047619 0.357142857,2.42857143 C0.595238095,2.66666667 0.87797619,2.78571429 1.20535714,2.78571429 Z M5.60267857,10.375 L5.60267857,7.07142857 C5.60267857,6.55059524 5.67708333,6.15625 5.82589286,5.88839286 C6.01934524,5.53125 6.35416667,5.35267857 6.83035714,5.35267857 C7.30654762,5.35267857 7.6264881,5.55357143 7.79017857,5.95535714 C7.87946429,6.19345238 7.92410714,6.58035714 7.92410714,7.11607143 L7.92410714,7.11607143 L7.92410714,10.375 L10,10.375 L10,6.71428571 C10,5.6875 9.85119048,4.92857143 9.55357143,4.4375 C9.16666667,3.82738095 8.48958333,3.52232143 7.52232143,3.52232143 C7.04613095,3.52232143 6.62946429,3.64136905 6.27232143,3.87946429 C5.94494048,4.07291667 5.70684524,4.31845238 5.55803571,4.61607143 L5.55803571,4.61607143 L5.53571429,4.61607143 L5.53571429,3.70089286 L3.54910714,3.70089286 L3.54910714,10.375 L5.60267857,10.375 Z M2.23214286,10.375 L2.23214286,3.70089286 L0.15625,3.70089286 L0.15625,10.375 L2.23214286,10.375 Z" id="" fill="#FFFFFF" fill-rule="nonzero"></path>
					</g>
				</svg>
				<span class="social-label">Share on LinkedIn</span>
			</li>

			<li class="link" v-on:click="clickItem('link-chart')">
				<svg width="15px" height="8px" viewBox="0 0 15 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<defs></defs>
					<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<g id="onesub/story/article-alternative-desktop" transform="translate(-749.000000, -578.000000)" fill="#000000" fill-rule="nonzero">
							<g id="Group-9" transform="translate(676.000000, 568.000000)">
								<g id="link-2" transform="translate(72.700000, 10.000000)">
									<path d="M9.1454548,1.5818182 C8.82412548,1.5818182 8.5636366,1.32132932 8.5636366,1 C8.5636366,0.678670681 8.82412548,0.4181818 9.1454548,0.4181818 L10.8909094,0.4181818 C11.8167561,0.4181818 12.7046825,0.785972963 13.359355,1.44064543 C14.0140274,2.0953179 14.3818186,2.98324432 14.3818186,3.909091 C14.3818186,5.83706691 12.8188853,7.4000002 10.8909094,7.4000002 L9.1454548,7.4000002 C8.82412548,7.4000002 8.5636366,7.13951132 8.5636366,6.818182 C8.5636366,6.49685268 8.82412548,6.2363638 9.1454548,6.2363638 L10.8909094,6.2363638 C12.1762267,6.2363638 13.2181822,5.19440828 13.2181822,3.909091 C13.2181822,3.29185988 12.9729881,2.69990893 12.5365398,2.26346062 C12.1000915,1.82701231 11.5081405,1.5818182 10.8909094,1.5818182 L9.1454548,1.5818182 Z M5.6545456,6.2363638 C5.97587492,6.2363638 6.2363638,6.49685268 6.2363638,6.818182 C6.2363638,7.13951132 5.97587492,7.4000002 5.6545456,7.4000002 L3.909091,7.4000002 C2.98324432,7.4000002 2.0953179,7.03220904 1.44064543,6.37753657 C0.785972963,5.7228641 0.4181818,4.83493768 0.4181818,3.909091 C0.4181818,1.98111509 1.98111509,0.4181818 3.909091,0.4181818 L5.6545456,0.4181818 C5.97587492,0.4181818 6.2363638,0.678670681 6.2363638,1 C6.2363638,1.32132932 5.97587492,1.5818182 5.6545456,1.5818182 L3.909091,1.5818182 C2.62377372,1.5818182 1.5818182,2.62377372 1.5818182,3.909091 C1.5818182,4.52632212 1.82701231,5.11827307 2.26346062,5.55472138 C2.69990893,5.99116969 3.29185988,6.2363638 3.909091,6.2363638 L5.6545456,6.2363638 Z M5.0727274,4.4909092 C4.75139808,4.4909092 4.4909092,4.23042032 4.4909092,3.909091 C4.4909092,3.58776168 4.75139808,3.3272728 5.0727274,3.3272728 L9.727273,3.3272728 C10.0486023,3.3272728 10.3090912,3.58776168 10.3090912,3.909091 C10.3090912,4.23042032 10.0486023,4.4909092 9.727273,4.4909092 L5.0727274,4.4909092 Z" id="Combined-Shape"></path>
								</g>
							</g>
						</g>
					</g>
				</svg>
				<transition name="fadeup" mode="out-in">
					<span class="popup" v-if="showPopup">Link copied to clipboard</span>
				</transition>
				<span class="social-label link">Copy Link</span>
			</li>

		</ul>
	</div>
	<div v-else>
		<ul class="flex">
			<li class="link" v-on:click="clickItem('link')">
				<svg width="15px" height="8px" viewBox="0 0 15 8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<defs></defs>
					<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<g id="onesub/story/article-alternative-desktop" transform="translate(-749.000000, -578.000000)" fill="#000000" fill-rule="nonzero">
							<g id="Group-9" transform="translate(676.000000, 568.000000)">
								<g id="link-2" transform="translate(72.700000, 10.000000)">
									<path d="M9.1454548,1.5818182 C8.82412548,1.5818182 8.5636366,1.32132932 8.5636366,1 C8.5636366,0.678670681 8.82412548,0.4181818 9.1454548,0.4181818 L10.8909094,0.4181818 C11.8167561,0.4181818 12.7046825,0.785972963 13.359355,1.44064543 C14.0140274,2.0953179 14.3818186,2.98324432 14.3818186,3.909091 C14.3818186,5.83706691 12.8188853,7.4000002 10.8909094,7.4000002 L9.1454548,7.4000002 C8.82412548,7.4000002 8.5636366,7.13951132 8.5636366,6.818182 C8.5636366,6.49685268 8.82412548,6.2363638 9.1454548,6.2363638 L10.8909094,6.2363638 C12.1762267,6.2363638 13.2181822,5.19440828 13.2181822,3.909091 C13.2181822,3.29185988 12.9729881,2.69990893 12.5365398,2.26346062 C12.1000915,1.82701231 11.5081405,1.5818182 10.8909094,1.5818182 L9.1454548,1.5818182 Z M5.6545456,6.2363638 C5.97587492,6.2363638 6.2363638,6.49685268 6.2363638,6.818182 C6.2363638,7.13951132 5.97587492,7.4000002 5.6545456,7.4000002 L3.909091,7.4000002 C2.98324432,7.4000002 2.0953179,7.03220904 1.44064543,6.37753657 C0.785972963,5.7228641 0.4181818,4.83493768 0.4181818,3.909091 C0.4181818,1.98111509 1.98111509,0.4181818 3.909091,0.4181818 L5.6545456,0.4181818 C5.97587492,0.4181818 6.2363638,0.678670681 6.2363638,1 C6.2363638,1.32132932 5.97587492,1.5818182 5.6545456,1.5818182 L3.909091,1.5818182 C2.62377372,1.5818182 1.5818182,2.62377372 1.5818182,3.909091 C1.5818182,4.52632212 1.82701231,5.11827307 2.26346062,5.55472138 C2.69990893,5.99116969 3.29185988,6.2363638 3.909091,6.2363638 L5.6545456,6.2363638 Z M5.0727274,4.4909092 C4.75139808,4.4909092 4.4909092,4.23042032 4.4909092,3.909091 C4.4909092,3.58776168 4.75139808,3.3272728 5.0727274,3.3272728 L9.727273,3.3272728 C10.0486023,3.3272728 10.3090912,3.58776168 10.3090912,3.909091 C10.3090912,4.23042032 10.0486023,4.4909092 9.727273,4.4909092 L5.0727274,4.4909092 Z" id="Combined-Shape"></path>
								</g>
							</g>
						</g>
					</g>
				</svg>
				<transition name="fadeup" mode="out-in">
					<span class="popup" v-if="showPopup">Link copied to clipboard</span>
				</transition>
			</li>

			<li class="facebook" v-on:click="clickItem('facebook')">
				<svg width="6px" height="10px" viewBox="0 0 6 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<g id="onesub/story/article-alternative-desktop" transform="translate(-687.000000, -577.000000)" fill="#FFFFFF">
							<g id="Group-9" transform="translate(676.000000, 568.000000)">
								<g id="Group">
									<path d="M12.5039062,19 L12.5039062,14.5273438 L11,14.5273438 L11,12.75 L12.5039062,12.75 L12.5039062,11.34375 C12.5039062,10.6015588 12.7122375,10.0253927 13.1289062,9.61523438 C13.545575,9.20507607 14.0989549,9 14.7890625,9 C15.3489611,9 15.8046857,9.02604141 16.15625,9.078125 L16.15625,10.6601562 L15.21875,10.6601562 C14.8671857,10.6601562 14.6263027,10.7382805 14.4960938,10.8945312 C14.3919266,11.0247402 14.3398438,11.2330715 14.3398438,11.5195312 L14.3398438,12.75 L16,12.75 L15.765625,14.5273438 L14.3398438,14.5273438 L14.3398438,19 L12.5039062,19 Z" id=""></path>
								</g>
							</g>
						</g>
					</g>
				</svg>
			</li>

			<li class="twitter" v-on:click="clickItem('twitter')">
				<svg width="11px" height="9px" viewBox="0 0 11 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
					<defs></defs>
					<g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
						<g id="onesub/story/article-alternative-desktop" transform="translate(-718.000000, -578.000000)" fill="#FFFFFF">
							<g id="Group-9" transform="translate(676.000000, 568.000000)">
								<g id="Group-8" transform="translate(33.000000, 0.000000)">
									<path d="M18.8613281,12.234375 C18.8756511,12.291667 18.8828125,12.384765 18.8828125,12.5136719 C18.8828125,13.544927 18.632164,14.5403598 18.1308594,15.5 C17.6152318,16.5169322 16.8919317,17.3261689 15.9609375,17.9277344 C14.9440053,18.6009148 13.7766993,18.9375 12.4589844,18.9375 C11.1985614,18.9375 10.0455781,18.6009148 9,17.9277344 C9.15755287,17.9420574 9.33658754,17.9492188 9.53710938,17.9492188 C10.5826875,17.9492188 11.5208292,17.6269563 12.3515625,16.9824219 C11.8502579,16.9824219 11.4098326,16.8356134 11.0302734,16.5419922 C10.6507142,16.2483709 10.3893236,15.8795595 10.2460938,15.4355469 C10.3893236,15.4498699 10.52539,15.4570312 10.6542969,15.4570312 C10.8548187,15.4570312 11.0553375,15.4355471 11.2558594,15.3925781 C10.7402318,15.2779942 10.3105486,15.0130229 9.96679688,14.5976562 C9.62304516,14.1822896 9.45117188,13.7096381 9.45117188,13.1796875 L9.45117188,13.1367188 C9.76627762,13.3229176 10.1028628,13.423177 10.4609375,13.4375 C10.1601547,13.2226552 9.9166676,12.9505225 9.73046875,12.6210938 C9.5442699,12.291665 9.45117188,11.930015 9.45117188,11.5361328 C9.45117188,11.1422506 9.55859268,10.7662778 9.7734375,10.4082031 C10.332034,11.1100296 11.0159465,11.6686177 11.8251953,12.0839844 C12.6344442,12.499351 13.4973912,12.7285154 14.4140625,12.7714844 C14.3854165,12.5996085 14.3710938,12.4277352 14.3710938,12.2558594 C14.3710938,11.8548157 14.4713532,11.4788429 14.671875,11.1279297 C14.8723968,10.7770165 15.1445295,10.5013031 15.4882812,10.3007812 C15.832033,10.1002594 16.2044251,10 16.6054688,10 C16.9348975,10 17.2392564,10.0644525 17.5185547,10.1933594 C17.797853,10.3222663 18.0449208,10.4941396 18.2597656,10.7089844 C18.7753932,10.6087235 19.2552061,10.4296888 19.6992188,10.171875 C19.5273429,10.7018256 19.1979191,11.117186 18.7109375,11.4179688 C19.1406271,11.3606768 19.5703104,11.2389332 20,11.0527344 C19.6848943,11.51107 19.3053408,11.9049463 18.8613281,12.234375 Z" id=""></path>
								</g>
							</g>
						</g>
					</g>
				</svg>
			</li>

		</ul>
	</div>
</template>

<script>
export default {
	name: 'SocialShare',
	data() {
		return {
			showPopup: false,
		}
	},
	props: {
		type: {
			type: String
		},
		shareURL: {
			type: String,
		},
		articleTitle: {
			type: String
		},
		chartTopics: {
			type: String
		},
		chartTopicsUrl: {
			type: String
		},
		showBorder: {
			type: Boolean
		},
		storySlug: {
			type: String
		},
		articleSlug: {
			type: String
		}
	},
	computed: {
		hasApp() {
			return this.$store.getters.hasApp;
		},
	},
	methods: {
		clickItem(type) {

			let newFacebookWindow;
			let newTwitterWindow;
			let newLinkedInWindow;
			let tempElement;
			let buildTweet = `OneSub - ${this.articleTitle} - Manage Your Bias. @onesub_`;
			let buildTweetEnd = `truenews`;
			switch(type) {
				case 'link':
					if (this.hasApp) {
						this.EventBus.$emit("appCallback", {
							type: 'socialCallback',
							data: {
								social: 'link',
								article: this.articleSlug,
								story: this.storySlug,
								heading: this.articleTitle,
							}
						});
					} else {
						tempElement = document.createElement('textarea');
						tempElement.value = `${document.location.origin}/${this.shareURL}`;
						document.body.appendChild(tempElement);
						tempElement.select();
						document.execCommand('copy');
						document.body.removeChild(tempElement);

						this.showPopup = true;
						setTimeout(() => {
							this.showPopup = false;
						}, 2000);
					}
				break;
				case 'facebook':
					if (this.hasApp) {
						this.EventBus.$emit("appCallback", {
							type: 'socialCallback',
							data: {
								social: 'facebook',
								article: this.articleSlug,
								story: this.storySlug,
								heading: this.articleTitle,
							}
						});
					} else {
						newFacebookWindow = window.open(`https://www.facebook.com/sharer/sharer.php?u=${document.location.origin}/${this.shareURL}`, '_blank');
						if (newFacebookWindow) {
							newFacebookWindow.focus();
						}
					}
				break;
				case 'twitter':
					if (this.hasApp) {
						this.EventBus.$emit("appCallback", {
							type: 'socialCallback',
							data: {
								social: 'twitter',
								article: this.articleSlug,
								story: this.storySlug,
								heading: this.articleTitle,
							}
						});
					} else {
						newTwitterWindow = window.open(`https://twitter.com/share?text=${buildTweet}&url=${document.location.origin}/${this.shareURL}&hashtags=${buildTweetEnd}`, '_blank');
						if (newTwitterWindow) {
							newTwitterWindow.focus();
						}
					}
				break;
				case 'link-chart':
					tempElement = document.createElement('textarea');
					tempElement.value = `${document.location.origin}/open/trends/${this.chartTopicsUrl}`;
					document.body.appendChild(tempElement);
					tempElement.select();
					document.execCommand('copy');
					document.body.removeChild(tempElement);

					this.showPopup = true;
					setTimeout(() => {
						this.showPopup = false;
					}, 2000);
				break;
				case 'facebook-share':
					newFacebookWindow = window.open(`https://www.facebook.com/sharer/sharer.php?u=${document.location.origin}/open/trends/${this.chartTopicsUrl}`, '_blank');
					if (newFacebookWindow) {
						newFacebookWindow.focus();
					}
				break;
				case 'twitter-chart':
					newTwitterWindow = window.open(`https://twitter.com/intent/tweet?text=Publisher sentiment over time for the topics ${this.chartTopics}. Learn more at ${document.location.origin}/open/trends/${this.chartTopicsUrl}`, '_blank');
					if (newTwitterWindow) {
						newTwitterWindow.focus();
					}
				break;
				case 'linkedin-chart':
					newLinkedInWindow = window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${document.location.origin}/open/trends/${this.chartTopicsUrl}&title=Publisher sentiment over time for the topics ${this.chartTopics}. Learn more at ${document.location.origin}/open/trends/${this.chartTopicsUrl}&summary=Publisher sentiment over time for the topics ${this.chartTopics}. Learn more at ${document.location.origin}/open/trends/${this.chartTopicsUrl}`, '_blank');
					if (newLinkedInWindow) {
						newLinkedInWindow.focus();
					}
				break
			}
		}
	}
};
</script>

<style lang="scss" scoped>
	@import '~styles/_variables';

	.emergency li {
		border: 1.5px solid $white-primary;
	}

	.flex {
		margin: 0;
		z-index: 2;
		li {
			position: relative;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 0.5em;
			min-width: 38px;
			min-height: 38px;
			border-radius: 50%;
			cursor: pointer;
			&.facebook {
				background: rgb(59, 89, 152);
			}
			&.twitter {
				background: rgb(0, 172, 237);
			}
			&.link {
				background: rgb(241, 211, 2);
			}

			@media (max-width: 475px) {
				width: 38px;
				height: 38px;
			}
		}

		.popup {
			background: rgb(16, 24, 24);
			border-radius: 2px;
			font-size: 11px;
			color: $white-primary;
			padding: 0.5em;
			width: 144px;
			display: block;
			text-align: center;
			position: absolute;
			top: 130%;
			left: 50%;
			margin-left: -13px;
			pointer-events: none;
			&::before {
				bottom: 100%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(0, 0, 0, 0);
				border-bottom-color: rgb(16, 24, 24);
				border-width: 5px;
				margin-left: -5px;
			}
		}
	}

	.flex-share {
		display: flex;
		flex-wrap: wrap;
		margin: 2em 0 0;
		li {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 0.5em;
			margin-top: 0.5em;
			height: 28px;
			border-radius: $border-radius;
			cursor: pointer;
			&.facebook {
				background: rgb(59, 89, 152);
			}
			&.twitter {
				background: rgb(0, 172, 237);
			}
			&.linkedin {
				background: rgb(0, 115, 177);
			}
			&.link {
				background: rgb(241, 211, 2);
			}
			svg {
				margin-left: 0.8em;
			}
			.social-label {
				font-family: $ff-tag;
				font-weight: bold;
				font-size: 11px;
				color: $white-primary;
				padding: 1em;
			}
			.link {
				color: $base-primary;
			}
		}

		.popup {
			background: rgb(16, 24, 24);
			border-radius: 2px;
			font-size: 11px;
			color: $white-primary;
			padding: 0.5em;
			width: 144px;
			display: block;
			text-align: center;
			position: absolute;
			top: 130%;
			left: 50%;
			margin-left: -72px;
			pointer-events: none;
			&::before {
				bottom: 100%;
				left: 50%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-color: rgba(0, 0, 0, 0);
				border-bottom-color: rgb(16, 24, 24);
				border-width: 5px;
				margin-left: -5px;
			}
		}
	}

</style>
