<template>
    <transition name="fade" mode="out-in">
        <div class="inbound-wrapper" v-if="visible">

            <div class="inbound-inner">
                <div class="area-first">
                    <global-svg v-if="actions.primary" :type="actions.primary.header" width="40px" height="40px"></global-svg>
                </div>
                <div class="area-second action-copy" v-if="actions.primary">
                    <h2>Our engine detected you {{actions.primary.status}}</h2>
                    <p>Did we get that right? Your feedback helps us improve your article recommendations.</p>
                </div>
                <div class="area-third" v-if="Object.keys(actions).length !== 0">
                    <div class="inner-actions">
                        <button class="action-primary btn-base btn-transparent" :class="actions.primary.class" v-on:click="actions.primary.click">
                            Yes, {{ actions.primary.name }}
                            <global-svg :type="actions.primary.icon" width="9px" height="9px" color="#FFF"></global-svg>
                        </button>
                    </div>
                    <div class="inner-actions">
                        <button class="action-secondary btn-base btn-transparent" :class="actions.secondary.class" v-on:click="actions.secondary.click">
                            {{ actions.secondary.name }}
                            <global-svg :type="actions.secondary.icon" width="9px" height="9px" :color="actions.secondary.color"></global-svg>
                        </button>
                        <button class="action-secondary btn-base btn-transparent" :class="actions.tertiary.class" v-on:click="actions.tertiary.click">
                            {{ actions.tertiary.name }}
                            <global-svg :type="actions.tertiary.icon" width="9px" height="9px" :color="actions.tertiary.color"></global-svg>
                        </button>
                    </div>
                </div>
            </div>

        </div>
    </transition>
</template>

<script>
    export default {
        data() {
            return {
                visible: false,
                actions: {},
                event: {}, 
            }
        },
        mounted () {
            this.EventBus.$on('didReturnFromArticle', (event) => {
                this.didReturn(event)
            });

            window.addEventListener("message", this.didReceiveEvent, false);
        },
        computed: {
            isLoggedIn() {
                return this.$store.getters.isLoggedIn;
            },

            getStory(slug) {
                return this.$store.getters.getStory(slug);
            },

            getArticle(slug) {
                return this.$store.getters.getArticle(slug);
            },

            detectMobile() {
                if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || this.$store.getters.hasApp) {
                    return true
                } else {
                    return false
                }
            },

            getVersionNumber() {
                return this.$store.state.packageVersion;
            }
        },
        methods: {
            didReturn(event) {
                
                this.event = event;

                // Whatever happens - guests should sign up:
                if (!this.isLoggedIn) {
                    this.showSignUp()
                    return
                }

                // Sometimes, we're told what happened:
                if (event.state){
                  this.trackArticleAction(event.state);
                  return;
                }

                // Otherwise proceed to construct & show the tool:
                let readProgress = event.article["read-progress"];
                if (!readProgress) {
                    return;
                }

                for (let i = 0; i < readProgress.length; i++) {
                    if (event.time <= readProgress[i].max) {
                        this.buildActions(readProgress[i]['treat-as']);
                        break;
                    }
                }
            },

            trackArticleAction(state) {
              // console.log("Tracking: ArticleInbound: trackArticleAction: ", state);
              
              let payload = {
                article: this.event.article,
                story: this.event.story.slug,
                state: state,
                time: this.event.time,
              };

              // Track to server:
              // console.log('Tracking: Emitting trackArticleAction', payload);
              this.EventBus.$emit('trackArticleAction', payload);

              // Preempt within store:
              this.$store.commit('updateReadStatus', {
                name: this.$options.filters.ucwords(payload.state),
                code: payload.state,
                last_at: new Date().toUTCString(), 
              });

              // console.log('Tracking: Closing overlay');
              this.visible = false;
            },

            buildActions(type) {
                let read = {name: 'I read in full',
                            class: 'action-read',
                            header: 'tick',
                            status: 'read this article in full.',
                            icon: 'tick-single',
                            color: '#00C619',
                            click: this.didRead}

                let skimmed = {name: 'I only skimmed',
                              class: 'action-skimmed',
                              header: 'skimmed',
                              status: 'skimmed this article.',
                              icon: 'skimmed-single',
                              color: '#FF8217',
                              click: this.didSkim}

                let glanced = {name: 'I only glanced',
                              class: 'action-glanced',
                              header: 'glanced',
                              status: 'glanced at this article.',
                              icon: 'glanced-single',
                              color: '#F73F3F',
                              click: this.didGlance}

                switch (type) {
                    case 'read':
                        this.actions = {primary: read, secondary: skimmed, tertiary: glanced}; 
                        this.visible = true;
                        return;
                    case 'skimmed':
                        this.actions = {primary: skimmed, secondary: read, tertiary: glanced};
                        this.visible = true;
                        return;
                    case 'glanced':
                        this.actions = {primary: glanced, secondary: skimmed, tertiary: read};
                        this.visible = true;
                        return;
                    default:
                        return;
                }
            },

            didRead() {
                this.trackArticleAction('read');
            },

            didSkim() {
                this.trackArticleAction('skimmed');
            },

            didGlance() {
                this.trackArticleAction('glanced');
            },

            // Listen for article callback from app
            didReceiveEvent(message) {
                if (message.data.oneSubEvent) {
                    if (message.data.oneSubEvent == 'closeArticle') {

                        const event = {
                            story: this.$store.getters.getStory(message.data.storySlug),
                            article: this.$store.getters.getArticle(message.data.articleSlug),
                            time: message.data.time
                        }

                        this.didReturn(event);
                    } else if (message.data.oneSubEvent == 'alternativeArticle') {

                        const event = {
                            story: this.$store.getters.getStory(message.data.storySlug),
                            article: this.$store.getters.getArticle(message.data.articleSlug),
                            time: message.data.time
                        }

                        this.EventBus.$emit("appCallback", {
                            type: 'storyCallback',
                            data: {
                                articleURL: this.$store.getters.getArticle(message.data.alternative).url,
                                articleSlug: message.data.alternative,
                                storySlug: message.data.storySlug,
                                articlePublisher: this.$store.getters.getArticle(message.data.alternative).publisher.name,
                                cookies: this.$store.getters.getArticle(message.data.alternative).publisher.rules.cookie_consent || [],
                                xpath: this.$store.getters.getArticle(message.data.alternative).publisher.rules.article_bottom
                            }
                        });

                        this.didReturn(event);
                    }
                }
            },

            showSignUp() {
                this.$store.commit("updateShowModal", 'skip');
            }
        },
    }
</script>

<style lang="scss" scoped>
  @import '~styles/_variables';

    .inbound-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $background-faded;
        z-index: 9999999999;

        .inbound-inner {
            display: flex;
            flex-direction: column;
            justify-items: center;
            align-items: center;
            max-width: 325px;

            h2, p {
                color: $white-primary;
                text-align: center;
            }

            h2 {
                font-weight: bold;
                font-size: 18px;
            }

            p {
                font-size: 14px;
            }

            .action-copy {
                margin: 18px;
            }

            .inner-actions {
                display: flex;
            }

            .action-primary,
            .action-secondary {
                display: flex;
                align-items: center;
                padding: 14px;
                height: 32px;
                font-weight: bold;
                color: $white-primary;
                border-radius: $border-radius;
                cursor: pointer;

                .svg-wrapper {
                    margin-left: 0.75em;
                }
            }

            .action-primary {
                padding: 0 14px;
                padding-right: 1em !important;
                font-size: 12px;
                color: $white-primary;

                &.action-read {
                    background: $highlight-read;
                }

                &.action-skimmed {
                    background: $highlight-skimmed;
                }

                &.action-glanced {
                    background: $highlight-glanced;
                }
            }

            .action-secondary {
                padding: 0 10px;
                font-size: 10px;
                margin-top: 12px;

                &.action-read {
                    border: 1px solid $highlight-read;
                    color: $highlight-read;
                }

                &.action-skimmed {
                    border: 1px solid $highlight-skimmed;
                    color: $highlight-skimmed;
                }

                &.action-glanced {
                    border: 1px solid $highlight-glanced;
                    color: $highlight-glanced;
                }

                &:last-child {
                    margin-left: 12px;
                }
            }
        }

        h2 {
            color: $white-primary;
        }
    }


    // Animation ----------------------------

    .area-first,
    .area-second,
    .area-third {
      animation: 0.25s fadeUp ease-in-out;
      animation-fill-mode: forwards;
      opacity: 0;
    }
    
    .area-second {
        animation-delay: .2s
    }

    .area-third {
        animation-delay: .4s;
    }

    @keyframes fadeUp {
        from {
            opacity: 0;
            transform: translate3d(0,40px,0)
        }

        to {
            transform: translate3d(0,0,0);
            opacity: 1;
        }
  }

</style>