import Vue from 'vue'
var _ = require('lodash/core');

const EventBus = new Vue({

	data: function () {
		return {
			// Which events do we want to listen to?
			// events: ['click', 'keydown', 'contextmenu'],
			// events: ['keydown']
			events: false
		}
	},

	created() {
		if(!this.events) {
			return;
		}
		this.handleEvents();
	},

	methods: {
		handleEvents() {
			_.forEach(this.events, (eventType) => {
				window.addEventListener(eventType, (event) => {
					this.EventBus.$emit(`DOM-${eventType}-fire`, event);
				});
			});
		}
	}

})

Object.defineProperties(Vue.prototype, {
	EventBus: {
		get: function () {
			return EventBus
		}
	}
})
