<template>
    <div>
    </div>
</template>

<script>
    export default {
        mounted () {
            this.EventBus.$on("didSelectSubscribe", (payload) => {
				this.handleSubscribeClick(payload);
            });
            this.EventBus.$on("didSelectRestore", (payload) => {
				this.handleRestoreClick(payload);
			});
        },

        computed: {
            hasApp() {
				return this.$store.getters.hasApp;
            },
            hasIOSApp() {
                return this.$store.getters.hasIOSApp;
            }
        },

        methods: {
            handleSubscribeClick(payload) {
              if (this.hasApp && this.hasIOSApp) {
                this.handleInApp(payload);
              } else {
                this.handleInBrowser();
              }
            },

            handleRestoreClick(payload) {
                this.EventBus.$emit("appCallback", {
                    type: 'restoreCallback',
                    data: {
						test: payload.test,
					}
				});
            },

            handleInApp(payload) {
                this.EventBus.$emit("appCallback", {
					type: 'subscribeCallback',
					data: {
						test: payload.test,
					}
				});
            },

            handleInBrowser() {
                this.EventBus.$emit('modal:go-pro');
                // document.location.hash = '#go_pro';
            }
        },
    }
</script>