export default {
  props: {
    view: Number,
    slug: String,
    node: String,
  },
  computed: {
    levelIsRoot() {
      return this.view == 1000;
    },
    levelIsPage() {
      return this.view == 100;
    },
    levelIsDetail() {
      return this.view == 10;
    },
  }
}