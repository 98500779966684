<template>
  <transition name="fade">
    <div class="modal-container" v-if="visible" :class="`mq-${$mq}`">

      <transition name="fadeup">
        <div class="modal-base" v-if="slide">
          <slot></slot>
        </div>
      </transition>

      <div class="modal-background" @click="modalClose()"></div>
    </div>
  </transition>
</template>

<script>
  export default {
    data() {
      return {
        slide: false,
      }
    },

    props: {
      visible: {
        type: Boolean,
        default: false
      },
    },

    methods: {
      modalClose() {
        document.body.style.overflow = 'unset';
        this.slide = false;
        setTimeout(() => {
          this.$emit('close');
        }, 10)
      },

      modalOpen() {
        document.body.style.overflow = 'hidden';
      }
    },

    watch: {
      visible: {
        immediate: true, 
        handler (willShow) {
          if (willShow) {
            this.modalOpen();
            setTimeout(() => {
              this.slide = true;
            }, 10)
          }
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/__2020_v3.scss';

  .modal-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100000001;

    .modal-background {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10000000;
      background: rgba($neutral-300, 0.5);
    }

    .modal-base {
      width: 100%;
      max-width: 25rem;
      max-height: 40rem;
      padding: 2.6rem 2.2rem;
      background: $neutral-600;
      color: $neutral-100;
      border-radius: 40px;
      z-index: 10000001;

      .darkmode & {
        background: $neutral-100;
        color: $neutral-600;
      }
    }

    &.mq-sm {
      align-items: flex-end;

      .modal-base {
        width: calc(100% - 10px);
        margin-bottom: 5px;
        max-height: 80vh;
        margin-top: auto;
      }
    }
  }

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }

  .fadeup-enter-active,
  .fadeup-leave-active {
    transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
  }

  .fadeup-enter,
  .fadeup-leave-to {
    opacity: 0;
    transform: translateY(100px);
  }
</style>