import Vue from 'vue';
// import API from 'app/axios';
import store from "./store";
import router from './router';
import utils from '@assets/js/utils.js';
// import { timeHours } from 'd3';

/**
 *  Ascii
 *  https://patorjk.com/software/taag/#p=display&h=2&v=3&c=c%2B%2B&w=%20&f=ANSI%20Regular&t=Topic
 */

const deckEngine = new Vue({
  mixins: [utils],

  router,
  store,

  data: function () {
    return {
      columns: [],
    };
  },

  computed: {
    getColumns() {
      if (!this.columns.length) {
        this.loadColumns();
      }
      return this.columns;
    },
  },

  methods: {
    loadColumns() {
      if (this.$userEngine.getPref('deck.columns')) {
        this.columns = this.$userEngine.getPref('deck.columns');
      }
    },
  },

});

Object.defineProperties(Vue.prototype, {
	$deckEngine: {
		get: function () {
			return deckEngine
		}
	}
})
