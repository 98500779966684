<template>
  <div class="onboarding-wrapper">

    <div class="pledges">
      <global-svg type="5-pledges"/>
    </div>

    <div class="inner-content">
      <div>
        <h1>Manage your news bias.</h1>
        <h1>Free. Forever.</h1>
        <h2>You’ve read 3 events in 2 stories. OneSub keeps track of what you’re reading, what you’ve read and gives you the most important stories to read.</h2>
      </div>

      <div>
        <button>Get Started for Free</button>
      </div>
    </div>

    <div class="inner-image" :style="{backgroundImage: 'url(' + require('@assets/images/onboarding-background.jpg') + ')'}">
      <div class="inner-content">
        <h2>Join us on our mission to combat fake news and promote good, healthy journalism.</h2>
        <span>🤝 We’ll never show you ads. Never.</span>
        <span>🔒 We’ll never sell your data.</span>
        <span>💰 80% of our revenue goes directly to publishers.</span>
        <span>🤝  We will always reward loyalty.</span>
        <span>🗣 We will exist to inspire impartial, healthy debate.</span>

        <h3><router-link to="/mission" title="Mission" class="mission">Learn more</router-link> about our mission</h3>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/__2020_v3.scss';

  .onboarding-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    background: $white-primary;
    min-height: 385px;
    text-align: center;
    @include deep-shadow;

    .pledges {
      position: absolute;
      right: -35px;
      top: -30px;
    }

    .inner-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 2em;

      h1 {
        @include typo-size-headline;
        margin: 0;
      }

      h2 {
        @include typo-size-body;
        margin-top: 1em;
      }

      button {
        @include base-btn;
      }
    }

    .inner-image {
      background-size: cover;
      background-position: center;
      color: $white-primary;

      .inner-content {
        justify-content: flex-end;
        height: 100%;

        h2 {
          @include typo-size-small-heading;
          margin-bottom: 1em;
        }

        h3 {
          @include typo-size-small-heading;
          margin-top: 1em;

          .mission {
            box-shadow: 0px -2px $yellow-tone-3 inset;
          }
        }

        span {
          @include typo-size-body;
        }
      }
    }
  }
</style>