<template>
  <fragment v-if="expanded">
    <component :is="'Story'" :view="view" :slug="slug" :node="node" :story="story" type="stack" v-for="(story) in stories" :key="`nav-${story.slug}`" layout="small"/>
  </fragment>

  <div v-else-if="stories.length">
    <button @click="expandSection">Go deeper. Explore <span class="callout">{{ stories.length }}</span> more {{ pluralStories() }}.</button>
  </div>
</template>

<script>
  import { Fragment } from 'vue-fragment';
  export default {
    components: { Fragment },

    data() {
      return {
        expanded: false
      }
    },

    props: ['stories', 'view', 'slug', 'node'],

    methods: {
      expandSection() {
        this.expanded = !this.expanded;
      },

      pluralStories() {
        if (this.stories.length > 1) { return 'stories' }
        return 'story'
      }
    },
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/scss/__2020_v3.scss';

  button {
    @include ghost-btn;

    width: 100%;
    margin: 1em 0;

    .callout {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $white-primary;
      background: $orange-tone-3;
      width: 20px;
      height: 20px;
      padding-right: 0.1em;
      padding-bottom: 0.05em;
      border-radius: 50%;
      margin-right: 0.1em;
    }
  }
</style>