import Vue from 'vue';
import API from 'app/axios';
import store from "./store";
import router from './router'

/**
 *  Ascii
 *  https://patorjk.com/software/taag/#p=display&h=2&v=3&c=c%2B%2B&w=%20&f=ANSI%20Regular&t=Topic
 */




const userEngine = new Vue({
  router,
  store,

	data: function () {
		return {
      coretick: 1000 * 0.05,  // tick interval (10th second)
      coresync: 1000 * 5,    // sync interval (10s)
      coretime: null,
      trackOnce: {}, // Plausible event throttling..
		}
	},
  
  computed: {
    get() {
      return this.$store.getAccount || {};
    },

    getTimers() {
      return this.$store.getters.timers;
    },

    token() {
      return this.$store.getters.getToken;
    },

    // for the watchers
    visibleSlugs() {
      // until login, consider nothing visible... (timing)
      if (!this.token) {
        return [];
      }
      // need to include "pro" data
      let aWatch = [];

      // which editions are we monitoring?
      let aEditions = [
        // 'edition_v3', // do we need this anymore?
        'edition_v6',
        'edition_pro',
      ];
      
      // stack all the slugs..
      aEditions.forEach(sEdition => {
        aWatch.unshift(...(this.$store.getters.getLibraryItem(sEdition)?.stories?.map(a => a.slug) || []));
      });

      // add any topic slugs (for /deck .. but for topic pages when they get tarted up too, presumably!)
      aWatch = aWatch.concat(this.$store.getters.getAllTopicSlugs || []);
      aWatch = aWatch.concat(this.$store.getters.getAllTopicArticleSlugs || []);

      // return the list of slugs we care about..
      return aWatch;
    },


    // wrap the user's preferences (do we need individual getters?)
    prefs() {
      return this.$store.getters.getSettings || {
        build: '-',
        edition: {
          locale: '', // set by the API & locked in thereafter (actually, ignored by axios.js at the moment..)
        },
        layout: {
          next: 'section',
          theme: 'auto',
          feed: {
            cleanlog: {},
          },
        },
      };
    },

  },

	methods: {

    instantiate() {
      console.log(`[userEngine] -- User Loaded... (Is this ever called!?)`);
    },

    accountChecks() {
      let user = this.$store.getters.getAccount;
      if (!user.token) {
        // not logged in
        return;
      }
      console.log(`[userEngine] -- Account Loaded: `, user);

      // set the timezone
      this.setPref('edition.zone', Intl.DateTimeFormat().resolvedOptions().timeZone);

      // check for onboarding questions?
      if (user.token == 'ABC') {
        this.EventBus.$emit('coaching:show', {
          tab: `dashboard`,
          state: `personalise`,
          content: {
            dial:  `content`, // - Default is false, to show large dial above, set to `full`. To show dial smaller and left of content, set to `content`
            heading: `Heading text`, // - Don`t include to hide heading.
            text: `Paragraph text`, // - Don`t include to hide paragraph.
            cta: {
              text: `Got it!`, // - Button text. Don`t include to hide button.
              action: {
                type: `close`, // - No action needed if it`s just to close, will close by default, otherwise pass `route` to navigate to another route and close coaching.
                route: `` // - Set type to `route` and pass the route you want it to go.
                // Action designed like this to have some flexibiltiy later with different types of actions
              }
            }
          }
        });
      }
    },


    coachSync(state) {
      // max: once a day (or a reload?)
      let tOnce = this.getPrefTime('coaching.guest_story_swipe');
      if (tOnce && (tOnce < 86400)) {
        return;
      }
      // time the right state?
      if (state != 'glanced') {
        return;
      }
      // okay - coach!
      this.setPref('coaching.guest_story_swipe', new Date().toUTCString());
      this.EventBus.$emit('coaching:show', {
          tab: `dashboard`,
          content: {
            heading: `Track your reading habits`, // - Don`t include to hide heading.
            text: `Nourish helps you track what you read, so you can read a healthier news diet.`, // - Don`t include to hide paragraph.
            cta: {
              text: `Create my free Nourish account.`, // - Button text. Don`t include to hide button.
              action: {
                type: `event`,
                event: `modal:auth`,
              }
            },
          }
        });
    },





    reload() {
      API.getAccount(this.$store.getters.getToken);
    },

    getPref(path){
      return path ? path.split('.').reduce((a,c) => { return a ? a[c] : null }, this.prefs) : this.prefs;
    },

    getPrefTime(path) {
      let v = this.getPref(path);
      if (!v) {
        return false;
      }
      return Math.round((new Date() - new Date(v)) / 1000);
    },

    setPref(path, newValue, bForceSave){

      
      let steps = path.split('.');
      let iterator = (i) => {
        if (i < steps.length) {
          // get the current value at this step..
          // *REALLY* important to clone the object here (otherwise we're playing with references!)
          let stepValue = {...this.getPref(steps.slice(0, i).join('.')) || {}};
          // overwrite the key at this position with an iteration deeper
              stepValue[steps[i]] = iterator(i + 1);
          // and return..
          return stepValue;
        } else {
          // reached the end of the path -- overwrite what we have!
          return newValue;
        }
      };

      // start at the bottom & iterate!
      let newSettings = iterator(0);

      
      
      // ------------------------ COMMENT OUT AS ONE BLOCK! ---------------------------------

      // let sOldFingerprint = JSON.stringify(this.prefs);
      // let sNewFingerprint = JSON.stringify(newSettings);

      // console.log('Setings: (Old): ', sOldFingerprint);
      // console.log('Setings: (New): ', sNewFingerprint);

      // let bPossibleChange = (sOldFingerprint !== sNewFingerprint);

      // console.log(`Set: ${path} = ${newValue} :: (Change possible: ${bPossibleChange})`, newSettings);

      // ====================================================================================





      this.$store.commit('updateSettings', newSettings);

      // NORMALLY deep watcher will store, only when actually changed against the DB.. 
      // Deck doesn't always appear to work, somehow, through the watcher..
      if (bForceSave) {
        API.patchSettings(newSettings);
      }

      // in case anything wants to re-think
      this.EventBus.$emit('user:prefs', {
        path: path,
        to: newValue,
      });

      // return
      return newSettings;
    },
    
    navChange(to, from) {
      this.notice(`Nav change:`, to, from);

      // Start/Stop timers
      this.notice(`[NavChange] : STOP  :`, from.params);
      this.navStopTimer(from.params);
      this.notice(`[NavChange] : START :`, to.params);
      this.navStartTimer(to.params);

      // hit the API for new slugs
      this.notice(`[NavChange] : SYNC  :`, [to.params.slug, to.params.node]);
      this.syncReadState([to.params.slug, to.params.node]);
    },

    syncReadState(aSlugs) {
      // filter
      let iStart = aSlugs.length;
      aSlugs = aSlugs.filter(s => {
        // this.notice(`Slug: ${s}, State: `, !this.$store.getters.readState(s)?.slug, this.$store.getters.readState(s)?.slug);
        return !this.$store.getters.readState(s)?.slug;
      });
      aSlugs = aSlugs.filter(s => s != '...');
      aSlugs = aSlugs.filter(Boolean);
      let iEnd = aSlugs.length;

      // check for both (means we can remove the debug below without compiler crying)
      if (!iStart || !iEnd) {
        return;
      }

      // redux?
      let iRedux = Math.abs(Math.round(((iEnd - iStart) / iStart) * 100));

      this.notice(`Syncing Read State (${iRedux}%) : `, aSlugs.length);
      API.getReadStateV3(aSlugs).then(({data}) => {
        Object.values(data).forEach(a => {
          this.$store.commit('setReadState', a);
        });
      });
    },

    readState(slug) {
      return this.$store.getters.readState(slug) || {};
    },
















    /**
     * 
     *  ------------------- Core Timing Mechanism -----------------------------
     */

    navStopTimer(p){
      return this.navTimer('stop', p, 0);
    },
    navStartTimer(p){
      return this.navTimer('start', p, 0);
    },

    navTimer(m, p, i) {
      
      // create a slug for this specific object...
      let sTimerSlug = [p.mode, p.slug, p.node].filter(Boolean).join(':');

      if (!sTimerSlug){
        // this.notice(`${m} timer: '${sTimerSlug}' [No timer slug?]`, m, p);
        return;
      }

      if (![
        'story',
      ].includes(p.mode)){
        this.notice(`${m} timer: '${sTimerSlug}' [NOPE - NOT A STORY]`, m, p);
        return;
      }

      // if we've got this far and *don't* have a node (because we tidied the URL structure for SEO) then compensate!
      if (!p.node) {
        let oClusters = this.$dataEngine.clustersGet();
        // this.error(`${m} timer: '${sTimerSlug}' -- Waiting for node ID - compensating! `, {...p}, oClusters);
        if (!oClusters) {
          if (i > 25) {
            // this.error(`${m} timer: '${sTimerSlug}' -- Waiting for node ID - Giving up! `, {...p}, oClusters);
            return;
          }
          window.setTimeout(() => {
            // this.notice(`Restarting timer (iteration: ${i})`);
            this.navTimer('start', p, ++i);
          }, 250);
        }
        return;
      }

      // this.notice(`${m} timer: '${sTimerSlug}' `, m, p);
      
      // identify the timer & create if necessary
      if (!this.$store.getters.timer(sTimerSlug)){
        // this.notice(`${m} created, ${sTimerSlug}`);
        this.$store.commit('timer',{
          slug: sTimerSlug,
          timer: {
            meta: p,
            seconds: 0,
            elapsed: 0,
            moments: 0,
            current: 0,
            running: false, // epoch of last running time
            periods: [], // tracking periods
            syncnxt: 0,
          },
        });
      }

      let timer = this.$store.getters.timer(sTimerSlug);

      if (m == 'start') {

        timer.running = new Date().getTime();
        timer.syncnxt = timer.running;

      } else if (m == 'stop') {

        // careful this doesn't happen twice (and add all of time() to the stack!)
        if (timer.running) {

          // current elapsed time
          let elapsed = new Date().getTime() - timer.running;
          // stack 
          timer.periods.push(elapsed);
          // clear the timer 
          timer.running = false;
          timer.syncnxt = false;

        }
      }

      this.$store.commit('timer', {
        slug: sTimerSlug,
        timer: timer,
      });

      // calcs..
      this.calcElapsed(sTimerSlug);

      // sync..
      this.sync(sTimerSlug, m);

      return this.$store.getters.timer(sTimerSlug);
    },

    // update a 
    calcElapsed(sTimerSlug) {
      let timer = this.$store.getters.timer(sTimerSlug);

      timer.current = timer.running ? new Date().getTime() - timer.running : 0;

      // calculate total elapsed time.
      // work around blur/focus bug
      timer.elapsed = timer.periods.reduce((a, c) => {
        // ignore any periods > 3 minutes
        let iMaxMinsPerPeriod = 3; // minutes
        let iMaxMillisecondsPerPeriod = iMaxMinsPerPeriod * 60 * 1000; // milliseconds
        return a < iMaxMillisecondsPerPeriod ? a + c : c;
      }, 0) + timer.current;

      timer.moments = timer.periods.length;
      timer.seconds = (timer.elapsed / 1000).toFixed(2);

      this.$store.commit('timer',{
        slug: sTimerSlug,
        timer: timer,
      });

      // sync? (intervals only..)
      if (timer.running && ((new Date().getTime()) > timer.syncnxt)){
        // sync: 
        this.sync(sTimerSlug, 'interval');
        // this.notice(`Update: ${sTimerSlug} (${timer.running}) -- ${timer.current} / ${timer.elapsed}`);
      }
    },

    /**
     *  ------------------- MAIN TICK! -----------------------------
     */


    tick(){
      // this.notice(this.$store.getters.timers_runnning_keys);
      this.$store.getters.timers_runnning_keys.forEach((slug) => { this.calcElapsed(slug) });
    },

    /**
     *  ------------------- Core Timing Mechanism -----------------------------
     */


    sync(sTimerSlug, mode) {


      // load the template
      let payload = this.getSyncPayloadTemplate();

      // load the timer data
      let timer = this.$store.getters.timer(sTimerSlug);

      // update the syncnxt
      let lag = (new Date().getTime()) - timer.syncnxt;
      timer.syncnxt = (new Date().getTime()) + this.coresync;
      let nxt = (timer.syncnxt - new Date().getTime());
      this.$store.commit('timer',{
        slug: sTimerSlug,
        timer: timer,
      });

      // ffs, unused vars
      this.debug(`Sync (${mode}) (${lag}, ${nxt})`);

      // build the payload
      let iMaxMinutes = 1.5; // ie 90s
      
 
 
      // max 90s per asset (story/article)
      // need to deal with the concept of 'focus'
      payload.time = Math.min(60 * iMaxMinutes, Math.round(timer.elapsed / 1000)); // ms ➜ s
     
      // don't hammer the server in the first 5 seconds
      // (October, SEO fiddling..)
      if (payload.time < 5) {
        return;
      }

      // and give up after 60s * 3m = 180s too -- no-one cares
      // NB: the time is capped to MAX(60 * iMaxMinutes) above .. so it's never > ...
      // ..: this does mean we never actually record 90s .. which isn't ideal.  Needs tidying.
      if (payload.time >= (60 * iMaxMinutes)) {
        return;
      }

 


      payload.story = timer.meta.slug || '';
      
      // removed: april 2024, cos it's super misleading.  They've not *actually* read the node article, it's arbitrary..
      // payload.article = timer.meta.node || '';
      payload.article = '';

      // including the state (very assumed for now, might/could come from read-time :shrug:)
      payload.state = this.getSyncState(timer);

      // into which day do we correlate the data?
      payload.offset = new Date().getTimezoneOffset();
      
      this.notice(`Sync (${mode}) (Current: ${timer.current}) (${lag}/${nxt}): SEND : ${payload.state}, ${payload.time} `, payload);
      // convert to something that *looks* like the response object
      let oCmmtPayload = {
        unread: 0,
        slug: payload.story,
        type: "story",
        name: payload.state.charAt(0).toUpperCase() + payload.state.slice(1),
        code: payload.state,
        px: Math.min(1, Math.round((payload.time / 90) * 100) / 100),
        scope: payload.scope,
        platform: payload.platform,
        time: payload.time,
        date: (new Date()).toUTCString(),
        updated: false
      }
      this.$store.commit('setReadState', oCmmtPayload);
  
      if (!this.$store.getters.isLoggedIn) {
        this.coachSync(payload.state);
      }


      // sync with HQ
      this.pushSync(payload);
    },

    pushSync(payload) {
      // if (payload) {
      //   return;
      // }

      // no anon for now..
      if (!this.$store.getters.isLoggedIn) {
        this.notice('Sync: (Not logged in)');
        return;
      }

      API.post('/track/article/read',payload).then(({data}) => {

        // this.notice(`Sync (${mode}) (Current: ${timer.current}) (${lag}/${nxt}): RECV : `, data?.status);

        // map in all the updated story/article statuses..
        if (data?.status) {
          Object.values(data?.status).forEach(a => {
            // this.notice(`Sync (${mode}) (Current: ${timer.current}) (${lag}/${nxt}): CMMT : `, a.slug, a);
            this.$store.commit('setReadState', a);
          });
        }

        // update the dial
        if (data?.dial) {
          // console.log({...data?.dial?.rings?.time});
          this.$store.commit('updateDial', data?.dial);
        }
      }).catch((error) => {
        this.error(error);
      });
    },


    /**
     * Both updated to handle arrays of slugs (for /deck) - end April 2024
     */

    markRead(mSlugs, template = {}) {
      let oTimer = this.navStopTimer(this.$route.params);
      console.log(`Timer:: `, oTimer);

      // a little legacy tidying
      template = ((typeof template == 'string') && template.length) ? {state: template} : template;
      template.state = template.state || 'read';

      // force array structure (/deck commits whole columns now)
      let aSlugs = !Array.isArray(mSlugs) ? [mSlugs] : mSlugs;
      aSlugs.forEach(slug => {
        let oCmmtPayload = {
          ...this.getSyncPayloadTemplate(template),
          unread: 0,
          slug: slug,
          type: "story",
          name: template.state.charAt(0).toUpperCase() + template.state.substring(1),
          code: template.state,
          px: 1,
          date: (new Date()).toUTCString(),
          updated: false
        }
        this.$store.commit('setReadState', oCmmtPayload);
      });
      
      // sync with HQ
      this.pushSync({
        ...this.getSyncPayloadTemplate(template),
        story: aSlugs.join(','),
        state: template.state,
      });
    },

    markArticleRead(mSlugs, template = {}) {

      // a little legacy tidying
      template = ((typeof template == 'string') && template.length) ? {state: template} : template;
      template.state = template.state || 'read';

      // force array structure (/deck commits whole columns now)
      let aSlugs = !Array.isArray(mSlugs) ? [mSlugs] : mSlugs;
      aSlugs.forEach(slug => {
        let oCmmtPayload = {
          ...this.getSyncPayloadTemplate(template),
          unread: 0,
          slug: slug,
          type: "article",
          name: template.state.charAt(0).toUpperCase() + template.state.substring(1),
          code: template.state,
          px: 1,
          date: (new Date()).toUTCString(),
          updated: false
        }
        this.$store.commit('setReadState', oCmmtPayload);
      });

      // sync with HQ
      this.pushSync({
        ...this.getSyncPayloadTemplate(template),
        article: aSlugs.join(','),
        state: template.state,
      });
    },






    getSyncState(timer) {

      // load article?
      // if (timer.meta.node) {
      //   let article = this.$store.getters.getArticle(timer.meta.node);
      //   this.notice('Article: ', article);
      // }

      let seconds = timer.elapsed / 1000;
      let state = 'clicked';
      if (seconds > 30) {
        state = 'read';
      } else if (seconds > 15) {
        state = 'skimmed';
      } else if (seconds > 5) {
        state = 'glanced';
      } else {
        state = 'glanced';
      }

      return state;
    },

    getSyncPayloadTemplate(template = {}) {
      // platform, version, scope (browser) defaults:
      let platform = this.$store.getters.hasMobile ? 'mobile' : 'desktop';
      let version = '';
      let scope = 'browser';

      // unless in app:
      if (this.$store.getters.hasApp) {
        // platform = navigator.vendor.match('/Google/') ? 'android' : 'apple';
        platform = this.$store.getters.hasAppPlatform || 'unknown';
        version = this.$store.getters.hasAppVersion;
        scope = 'app';
      }

      return {
        article: null,
        story: null,
        state: null,
        time: null,
        scope: scope,
        version: version,
        platform: platform,
        ...template,
      }
    },

    // legacy(payload) {
    //   // Only track opt-in, real users..
    //   if (!this.loggedIn) {
    //     // console.log('Tracking: Not logged in');
    //     return;
    //   }
      
    //   // time: optional
    //   payload.time = payload.time || 0;

    //   // story: is optional
    //   let story = (payload.story === undefined) ? '' : 
    //     ( typeof payload.story === 'object' ) ? payload.story.slug : payload.story;

      

    //   /**
    //    * ----------- SEND -------
    //    */
    //   let api_payload = {
    //     article: payload.article.slug,
    //     story: story,
    //     state: payload.state,
    //     time: payload.time,
    //     scope: scope,
    //     version: version,
    //     platform: platform
    //   };
    //   // console.log("Tracking: ", api_payload);
    //   this.deviceLog("Tracking: ....");
    //   API.post('/track/article/read',api_payload).then().catch((error) => {
    //     // console.log(error);
    //   });
    // },



    trackEvent(payload) {
      // should this event be throttled to once per session? (default: yes)
      let bOnce = (payload.bOnce !== false);

      // consider throttling to once per session
      if (bOnce && this.trackOnce[payload.label]){
        // console.log(`Plausible: Throttling: '${payload.label}'`);
        return;
      }
      // record that this event has been triggered at least once
      this.trackOnce[payload.label] = true;

      // track (or stack) to Plausible
      // console.log(`Plausible: Triggering event: '${payload.label}'`);
      window.plausible(payload.label, {props: payload.props});
    },






    debug(){},

    notice(){
      console.log(`\x1B[36mUserEngine --`, ...arguments);
    },

    error(){
      console.log(`\x1b[31mUserEngine --`, ...arguments);
    },



  }, // end methods

	created() {
    this.notice("Instantiated & ticking");
    this.coretime = window.setInterval(this.tick, this.coretick);
    // build the template of stuff that doesn't change per-session..
    this.payload_template = this.getSyncPayloadTemplate();
    // listen for account events (for sync, particularly)
    this.EventBus.$on('api:account', () => {
      this.accountChecks();
      this.tick()
    });
    // Plausible event tracking
    this.EventBus.$on('track:event', this.trackEvent);
    // globalise the setter & getter for prefs (still have to be an alpha for this to write new prefs)
    window._osxSetPref = this.setPref;
    window._osxGetPref = this.getPref;

    // trigger the navChange ... if we're not navigating to here the watcher (just below) doesn't go off... 
    // this.navChange(this.$route, this.$route);
  },

  watch: {
    $route(to, from) {
      // console.log(`\x1B[36m UserEngine -- $route change`, to, from);
      this.navChange(to, from);
    },
    visibleSlugs(to) {
      // console.log(`\x1B[36m UserEngine -- Visible Slugs Mutation:`, to);
      this.syncReadState(to);
    },
    prefs: {
      deep: true,
      handler: function(to, from) {
        // console.log("Settings change: ", {
        //   from: from,
        //   to: to,
        //   same: (to === from),
        //   similar: (to == from),
        //   json: (JSON.stringify(to) == JSON.stringify(from)),
        //   json_to: JSON.stringify(to),
        //   json_from: JSON.stringify(from),
        // });
        if (from.build == '-') {
          // console.log("Settings change: BUILD STOP (GUEST)");
          return; // guest.. 
        }
        if (JSON.stringify(to) == JSON.stringify(from)) {
          // console.log("Settings change: NO CHANGE");
          return; // exactly the same...
        }
        // commit to database
        // console.log("Settings change: ** PATCH **");
        API.patchSettings(to);
      }
    }
  }
})

Object.defineProperties(Vue.prototype, {
	$userEngine: {
		get: function () {
			return userEngine
		}
	}
})
