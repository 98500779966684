<template>

    <div class="profile-image" >
        <span class="intercom-messages" v-if="messages > 0"></span>
        <img :src="profilePicture" />
    </div>

</template>

<script>

// import axios from "axios";
// import Vue from 'vue';

export default {
    name: 'ProfileImagePartial',
    props: {
        profilePicture: {
            type: String
        },
        accountEmail: {
            type: String
        },
        messages: {
            type: Number
        }
    },
    mounted() {
        // this.$nextTick(function () {
		// 	this.hasGravatar();
		// });
    },
    methods: {
        // hasGravatar() {
		// 	this.$nextTick(function () {
		// 		if(this.$refs['gravatar']) {
		// 			let getGravatarHash = this.$refs['gravatar'].$el.src;
		// 			let gravatarHash = getGravatarHash.split("/")[4].split('?')[0];
        //             axios.get(`https://gravatar.com/avatar/${gravatarHash}.png?d=404`)
        //             .catch(error => {
        //                 console.error(error)
        //             })
        //         }
		// 	});
        // },
    }
}
</script>


<style lang="scss">

	@import '~styles/_variables';

    .profile-image {
        display: flex;
        justify-content: flex-end;
        img {
            display: block;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            object-fit: cover;
        }
        .intercom-messages {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 10px;
            height: 10px;
            background: $highlight-primary;
            border-radius: 50%;
            font-size: 10px;
            font-weight: bold;
            color: $base-primary;
        }
    }

</style>
